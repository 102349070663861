@import "../../../colors.scss";

.loading-container {
  .loading-label {
    padding-left: 5px;
    color: $white;
  }

  .loading-image {
    vertical-align: -5px;
  }
}

.loading-scroll {
  display: flex;
  flex: 1;
  margin-top: 20px;
  justify-content: center;
  align-self: center;
  .loading-label {
    color: $black;
  }
}

.loading-planning {
  position: absolute;
  left: 50%;
  display: flex;
  flex: 1;
  margin-top: 20px;
  .loading-label {
    color: $black;
  }
}

.loading-modal {
  .loading-label {
    color: $black;
  }
  padding-left: 90px;
  padding-top: 250px;
}

.loading-notify-modal {
  display: flex;
  justify-content: center;

  .loading-label {
    color: $black;
  }
}

.company-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: center;
  .loading-label {
    color: $black;
  }
}

.loading-dropdown {
  padding: 10px 0 10px 10px;
  .loading-label {
    color: $darkLandline;
  }
}

.buttonLoading {
  position: absolute;
}
