@import "../../../colors.scss";
.infoPopup {
  position: relative;
}
.popup__wrapper {
  position: absolute;
  .popup--shape {
    display: flex;
    align-items: center;
  }
  .popup__content {
    padding: 4px 14px;
    background-color: $white;
    box-shadow: 0 4px 16px 2px rgba(41, 35, 31, 0.075);
    border-radius: 4px;
    color: $darkLandline;
    font-size: 12px;
    font-weight: 400;
    display: grid;
    place-items: center;
  }
  .arrow-right {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left: 5px solid $white;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid $white;
    position: absolute;
    bottom: -5px;
    left: 50px;
  }
}
