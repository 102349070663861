@import "../../../colors.scss";

.company-card-container {
  width: 276px;
  height: 336px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 8px;
  padding-top: 32px;
  display: flex;
  margin-right: 16px;
  margin-top: 56px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.avatar-image {
  width: 88px;
  height: 88px;
  border: 1px solid $placeholder;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.avatar-company-image {
  img {
    border-radius: 50%;
    object-fit: cover;
  }
}
.avatar-text {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: $white;
}
.company-name {
  width: 240px;
  height: 64px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 8px;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.company-info {
  border-top: 1px solid $placeholder;
  flex: 1;
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
}
.phone-text {
  color: $gray050;
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
}
.phone-info {
  margin-top: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}
.email-text {
  color: $gray050;
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
}
.email-info {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  line-height: 20px;
}
.company-card-horizontal-container {
  flex-direction: row;
  height: 184px;
  width: 100%;
  padding: 0px 24px;
  cursor: auto;
  margin-right: 0px;
  position: relative;
  .company-info {
    border: none;
    flex-direction: row;
    display: flex;
    margin-top: 0px;
  }
  .company-info-wrapper {
    margin-left: 24px;
    height: 104px;
  }
  .company-name {
    width: 100%;
    height: 40px;

    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    justify-content: flex-start;
  }
  .avatar-image {
    width: 104px;
    height: 104px;
  }
  .avatar-text {
    line-height: 64px;
  }
  .phone-info {
    margin-right: 46px;
  }
  .dots-icon {
    position: absolute;
    right: 18px;
    top: 30px;
  }
}
