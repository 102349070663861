@import "../../../colors.scss";

// EMPTY STATE
.empty-state{
  background-image: url('/media/empty-state-left.png'), url('/media/empty-state-right.png');
  position: relative;
  background-color:$white;
  background-position: left top, right top;
  background-repeat:no-repeat, no-repeat;
  background-size: contain, contain;
  height:256px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 16px;
  svg{
    vertical-align:-5px;
  }
  .content{
    margin: auto;
    text-align: center;
    width:300px;
    padding-top: 24px;
  }
  img{
    padding-bottom: 25px;
  }
  &-label{
    span{
      padding-left: 5px;
    }
    font-family: "Source Sans Pro";
    font-size: 16px;
    color: $gray060;
    font-weight:600;
  }
}
