@import "../../../colors.scss";

.button-container {
  button {
    position: relative;
    border: none;
    border-radius: 6px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  &.fullWidth {
    button {
      width: 100%;
    }
  }
  &.disabled {
    button {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &.landlineColorDisabled {
    button {
      background-color: $primaryLandlineDisabled;
      color: $primaryLandlineDisabledGray;
    }
  }
  &.primaryColor {
    button {
      background-color: $darkLandline;
      border: 1px solid $darkLandline;
      color: $white;
    }
    button:hover {
      color: $primaryLandline;
      border: 1px solid $darkLandline;
    }
  }
  &.outlineColor {
    button {
      color: $darkLandline;
      background-color: $white;
      border: 1px solid $darkLandline;
    }
    button:hover {
      background-color: $mediumGray;
    }
  }
  &.landlineColor {
    button {
      color: $darkLandline;
      background-color: $primaryLandline;
      border: 1px solid $primaryLandline;
    }
    button:hover {
      background-color: $loyaltyLandline;
    }
  }
  &.grayColor {
    button {
      color: $darkLandline;
      background-color: $mediumGray;
      border: 1px solid $mediumGray;
    }
    button:hover {
      border: 1px solid $darkLandline;
      background-color: $lightGray;
    }
  }
  &.videcomColor {
    button {
      color: $darkLandline;
      background-color: $videcomInnerBtnColor;
      border: 1px solid $mediumGray;
      padding: 8px 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    button:hover {
      background-color: $videcomBtnHover;
    }
  }
  &.approveColor {
    button {
      color: $darkLandline;
      background-color: $white;
      border: 1px solid $white;
      box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
      svg {
        fill: $successGreen !important;
      }
    }
    button:hover {
      border: 1px solid $successGreen;
      background-color: $successGreen;
      color: $white;
      svg {
        fill: $white !important;
      }
    }
  }
  &.denyColor {
    button {
      color: $darkLandline;
      background-color: $white;
      border: 1px solid $white;
      box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
      svg {
        fill: $alertError !important;
      }
    }
    button:hover {
      border: 1px solid $alertError;
      background-color: $alertError;
      color: $white;
      svg {
        fill: $white !important;
      }
    }
  }
  &.newBookingColor {
    button {
      color: $darkLandline;
      background-color: $white;
      border: 1px solid $darkLandline;
    }
    button:hover {
      background-color: $hoverGreen;
    }

    &.disabled {
      button {
        color: $gray040;
        border: 1px solid $gray040;
      }
    }
  }
  &.smallSize {
    button {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      min-width: 70px;
      padding: 8px 16px;
    }
  }
  &.regularSize {
    button {
      padding: 8px 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      min-width: 93px;
    }
  }
  &.largeSize {
    button {
      padding: 12px 32px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      min-width: 115px;
    }
  }
  &.modalButtonSize {
    button {
      width: 56px;
      height: 32px;
      border-radius: 8px;
      color: $darkLandline;
      font-weight: 600;
    }
  }
  &.planningPageSize {
    button {
      width: 154px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &.newBookingSize {
    button {
      padding: 0 14px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
    }
  }
  &.statusSize {
    button {
      width: 119px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.driverStatusModalSize {
    button {
      width: 70px;
      height: 32px;
      font-size: 12px;
      font-weight: 600;
    }

    &.confirmation {
      button {
        width: 56px;
      }
    }
  }
  &.settings-size {
    button {
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      padding: 0px 24px;
    }
  }
  &.videcom-size {
    button {
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      padding: 0px 14px;
    }
  }
  &.update {
    button {
      font-weight: 600;
    }
  }
}
