@import "../../../colors.scss";

.input-wrapper {
  position: relative;
  .input-warning {
    position: absolute;
    font-size: 12px;
    bottom: -20px;
    color: $red;
  }
  .messages-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      display: inline-block;
      margin-bottom: 8px;
    }
  }
  input {
    position: relative;
    outline: none;
    box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  }

  &.regular-style {
    input {
      border: 1px solid $lightGray;
      background-color: $white;
      color: $gray050;
      box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
    }
    ::placeholder,
    ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+, Microsoft Edge */
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
    input:focus {
      border: 1px solid $gray070;
      box-shadow: 0 0 0 1px $lightGray, 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
      color: $darkLandline;
    }
  }
  &.search-style {
    input {
      border: 1px solid $lightGray;
      background-color: $white;
      border-radius: 8px;
    }
    ::placeholder,
    ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+, Microsoft Edge */
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
    input:focus {
      border: 1px solid $gray070;
    }
  }
  &.settings-style {
    width: 100%;
    margin-left: 35px;

    &.no-margin {
      margin-left: 0px;
    }
    label {
      color: $gray070;
      font-size: 12px;
      line-height: 16px;
    }
    input {
      color: $lightBlack;
      background-color: $mediumGray;
      border: none;
      height: 40px;
      border-radius: 8px;
      box-shadow: none;
    }
    ::placeholder,
    ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+, Microsoft Edge */
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
    input:focus {
      border: 1px solid $gray070;
      background-color: $mediumGray;
      color: $darkLandline;
      box-shadow: none;
      background-color: $mediumGray;
    }

    input {
      display: flex;
      width: 325px;
      height: 40px;
      box-shadow: none;
      padding-left: 36px;
    }

    &.no-margin {
      margin-left: 0;
    }

    &.disabled {
      input {
        color: $darkGray !important;
      }
    }
  }

  &.trip-details-modal-style {
    label {
      color: $gray070;
      font-size: 12px;
      line-height: 16px;
    }
    input {
      color: $lightBlack;
      background-color: $mediumGray;
      border: none;
      height: 40px;
      border-radius: 8px;
      box-shadow: none;
    }
    ::placeholder,
    ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+, Microsoft Edge */
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
    input:focus {
      border: 1px solid $gray070;
      background-color: $mediumGray;
      color: $darkLandline;
      box-shadow: none;
      background-color: $mediumGray;
    }
  }

  &.videcom-style {
    input {
      color: $lightBlack;
      background-color: $white;
      border: none;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $lightGray;
      box-shadow: none;
      width: 192px;
    }

    ::placeholder,
    ::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+, Microsoft Edge */
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
    input:focus {
      border: 1px solid $gray070;
      background-color: $white;
      color: $darkLandline;
      box-shadow: none;
    }
  }

  &.regular-size {
    input {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0px 12px;
      border-radius: 8px;
    }
  }
  &.search-size {
    input {
      display: flex;
      align-items: center;
      width: 355px;
      height: 40px;
      box-shadow: none;
      padding-left: 36px;
    }
  }
  &.search-driver-and-vehicle-size {
    input {
      display: flex;
      width: 100%;
      height: 40px;
      box-shadow: none;
      padding-left: 36px;
    }
  }
  &.trip-request-size {
    input {
      display: flex;
      width: 325px;
      height: 40px;
      box-shadow: none;
      padding-left: 36px;
    }
  }

  &.fullWidth {
    input {
      width: 100%;
    }
  }

  &.filter-style {
    input {
      border: 1px solid $lightGray;
      box-sizing: border-box;
      border-radius: 8px;
      height: 32px;
      padding: 6px 12px;
      box-shadow: none;
      margin-top: 24px;
    }
  }

  &.filter-search-style {
    input {
      border: 1px solid $lightGray;
      box-sizing: border-box;
      border-radius: 8px;
      height: 32px;
      padding: 6px 12px;
      box-shadow: none;
      cursor: pointer;
      flex: 1;
    }
  }
  &.location-search-style {
    label {
      color: $gray070;
      font-size: 12px;
      line-height: 16px;
    }
    input {
      color: $lightBlack;
      background-color: $mediumGray;
      border: none;
      height: 40px;
      border-radius: 8px;
      box-shadow: none;
      flex: 1;
      cursor: pointer;
    }
  }

  &.add-vehicle-style {
    input {
      border: 1px solid $lightGray;
      box-sizing: border-box;
      border-radius: 8px;
      height: 40px;
      padding: 6px 12px;
      box-shadow: none;
    }

    .placeholder {
      color: $gray050;
    }
  }

  &.input-error {
    input {
      background-color: $alertLightRed;
      border: 1px solid $alertBorderRed;
    }
    .messages-container {
      color: $red;
    }
  }
}
.mandatory-star {
  color: $primaryLandline;
  font-size: 16px;
  padding-right: 27px;
}

.input-icon-wrapper {
  position: relative;
  height: 40px;
}

.search-icon {
  position: absolute;
  left: 16px;
  top: 14px;
  fill: $gray050;
  width: 24px;
  height: 24px;
  justify-content: center;
}
