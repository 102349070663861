@import "../../../colors.scss";

.notifications-empty-state {
  padding-top: 15px;
  text-align: center;
  color: $gray090;
  font-weight: 600;

  .zero {
    font-size: 24px;
  }

  .label {
    padding-top: 10px;
    font-size: 16px;
    margin-bottom: 45px;
  }
}
