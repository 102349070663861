@import "../../../colors.scss";

.driver-profile-image {
  margin-left: 40px;
  margin-top: 40px;
  margin-right: 56px;
  width: 128px;
  height: 128px;
  position: relative;
}
.image-container {
  position: relative;
  border-radius: 50%;
  width: 128px;
  height: 128px;

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
.image-container:hover {
  background-color: $black;
  .driver-image {
    opacity: 0.6;
    border-radius: 50%;
  }
  .defaultDriverAvatar {
    opacity: 0.6;
    border-radius: 50%;
  }
}
.driver-image {
  border-radius: 50%;
  object-fit: cover;
}
.upload-container {
  position: absolute;
  top: 0;
  display: flex;
  width: 128px;
  height: 128px;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  .upload-text {
    color: $white;
    font-size: 18px;
    padding-top: 6px;
  }
}
.input-file {
  display: none;
}
.driver-avatar-file-input {
  cursor: pointer;
  position: absolute;
  width: 128px;
  height: 128px;
  z-index: 2;
  border-radius: 50%;
}
.driver-status-wrapper {
  position: absolute;
  right: 0;
  bottom: 5px;
  z-index: 3;
}
.defaultDriverAvatar {
  background-color: $primaryLandline;
  color: $black;
  text-transform: uppercase;
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 128px;
  height: 128px;
  font-size: 64px;
  font-weight: 600;
}
