@import "../../../colors.scss";

.notify-modal-container {
  position: relative;
  width: 392px;
  height: 568px;
  .title-wrapper {
    padding: 40px 50px 32px 50px;
  }

  .pnr-list-container {
    overflow-y: scroll;
    height: 368px;
    padding: 5px 50px;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 24px 50px 24px 0px;
  }

  .close-modal-icon-wrapper {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
