@import "../../../colors.scss";

.notification-item {
  display: flex;
  padding: 12px 0 15px 3px;
  cursor: pointer;
  background-color: rgb(248, 248, 248);
  .mozilla-bg {
    background-color: $white;
    width: 45px;
    height: 45px;
    border-radius: 17px;
    box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.24);
  }
  .close-icon-wrapper {
    position: absolute;
    right: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &.unread {
    border-bottom: 1px solid $mediumGray;
  }

  .dot-wrapper {
    width: 18px;
    height: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .info {
    padding: 0 0 0 10px;

    .label {
      color: $darkLandline;
      font-size: 20px;
      padding-top: 7px;
    }

    .description {
      color: $gray050;
      font-size: 12px;
      padding: 10px 0 7px 0;
    }

    .pnr {
      padding-right: 10px;
      font-size: 12px;
      color: $blue;
    }

    .created-date {
      padding-top: 10px;
      font-size: 12px;
      color: $gray050;
    }

    .pickup-date {
      padding-left: 4px;
      font-size: 12px;
      color: $blue;
    }

    .time {
      font-size: 12px;
      padding: 0 12px 0 4px;
      color: $blue;
    }

    .clock {
      vertical-align: -3px;
    }
  }
}
