.top-menu-container {
  display: flex;
  position: fixed;
  left: 200px;
  top: 0;
  height: 60px;
  width: calc(100% - 200px);
  background-color: #fff;
  align-items: center;
  padding: 10px;
  z-index: 100;
  justify-content: space-between;
}
