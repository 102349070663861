@import "../../../colors.scss";

.navigation-details-container {
  display: flex;
  flex-direction: row;

  .path-string {
    font-weight: 600;
    font-size: 24px;
    color: $gray050;

    &.isLast {
      color: $darkLandline;
    }

    &.clickable {
      cursor: pointer;
    }
  }
}

