@import "../../../colors.scss";

.table-operator-operations-container {
  cursor: initial;
  background-color: $white;
  top: 40px;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
  padding: 24px 0px;
  z-index: 2;
  position: absolute;
  width: 424px;
  background-color: $white;
  color: $darkLandline;

  &.show-on-top {
    transform: translate(0, -100%);
    top: -8px;
  }

  &.show-on-top:before {
    top: unset;
    border-bottom: unset;

    bottom: -6px;
    border-top: 6px solid $white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  .bottom-buttons {
    padding: 0px 24px;
    display: flex;
    justify-content: flex-end;
  }
  .operators-content {
    width: 100%;
    max-width: 424px;
    max-height: 320px;
  }
  .avatar-under-operator {
    min-height: 48px;
    width: 100%;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 24px;
  }
  .suggestion-wrapper {
    width: min-content;
    white-space: nowrap;
    padding-right: 16px;
  }
  .checkbox-avatar-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .avatar-name-container {
      padding-left: 12px;
    }
  }
  .checkbox-container {
    margin-left: 16px;
  }
  .tab-content {
    max-height: 320px;
    border-bottom: 1px solid $lightGray;
    margin-bottom: 16px;
    padding-top: 0px;
  }
}

.suggestion-not-allowed-text {
  margin-left: 12px;
  margin-bottom: 16px;
  padding-top: 24px;
}

.table-operator-operations-container:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-bottom: 6px solid $white;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  left: 133px;
  top: -6px;
}
