@import "../../../colors.scss";

.react-calendar {
  border-width: 0px;
  border-radius: 4px 4px 4px 4px;
  background: $white;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);

  .react-calendar__viewContainer {
    padding: 0 16px 41px 14px;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border-radius: 50%;
    background-color: $mediumGray;
  }

  .react-calendar__year-view__months {
    display: block !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    width: 32px;
    height: 32px;
    text-transform: none;
    color: $placeholder;
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    padding: 0;
    width: 32px;
    height: 32px;
    color: $black;
    margin-bottom: 8px;
  }

  .react-calendar__tile.react-calendar__year-view__months__month {
    padding: 0;
    width: 32px;
    height: 32px;
    color: $black;
    margin: 0px 21px;
    margin-bottom: 8px;
  }

  .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: $placeholder;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background-color: $darkLandline;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: white;
  }

  .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
    background-color: $darkLandline;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
  }

  .react-calendar__tile.react-calendar__tile--active {
    background-color: $darkLandline;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
  }

  .react-calendar__tile.react-calendar__tile--now {
    border-radius: 50%;
    background-color: $black;
  }
  .react-calendar__month-view__weekdays {
    font-weight: normal;
    color: $placeholder;
  }
  abbr {
    text-decoration: none;
  }
  .react-calendar__navigation {
    button {
      min-width: 30px;
      height: 40px;
    }

    button:enabled:hover {
      background-color: $mediumGray;
    }
  }

  .react-calendar__navigation__prev-button {
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    height: 22px;
    font-size: 18px;
  }
  .react-calendar__navigation__prev2-button {
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    height: 22px;
    font-size: 18px;
  }
  .react-calendar__navigation__next-button {
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    height: 22px;
    font-size: 18px;
  }
  .react-calendar__navigation__next2-button {
    padding: 0;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    height: 22px;
    font-size: 18px;
  }
  .react-calendar__navigation__label {
    font-size: 16px;
    font-weight: 600;
  }
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    max-width: 22px;
  }
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month {
    max-width: 22px;
  }

  &.driver-status-modal {
    box-shadow: unset;
    border: 1px solid $lightGray;
    height: 340px;

    .react-calendar__viewContainer {
      padding: 0 24px 0px 24px;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      border-radius: 0;
      background-color: $mediumGray;
    }

    .react-calendar__navigation__prev2-button {
      visibility: hidden;
    }

    .react-calendar__navigation__next2-button {
      visibility: hidden;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
      border-radius: 0;
    }

    .react-calendar__tile.react-calendar__tile--active {
      border-radius: 0;
      background-color: $placeholder;
      color: $black;
    }

    .react-calendar__tile--rangeEnd,
    .react-calendar__tile--rangeEnd:active,
    .react-calendar__tile--rangeEnd:focus,
    .react-calendar__tile--rangeStart,
    .react-calendar__tile--rangeStart:active,
    .react-calendar__tile--rangeStart:focus {
      background-color: $darkLandline !important;
      color: $white !important;
    }

    .react-calendar__tile.react-calendar__tile--now:not(.react-calendar__tile.react-calendar__tile--active) {
      background-color: $mediumGray !important;
      color: $black !important;
    }
  }
}
