@import "../../../colors.scss";
.landline-company-card-container {
  flex-direction: column;
  height: 320px;
  width: 100%;
  padding: 0px 24px;
  cursor: auto;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 8px;
  padding-top: 40px;
  margin-top: 56px;
  position: relative;
  display: flex;
  .landline-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 58px;
  }
  .landline-info-wrapper {
    height: 104px;
    display: flex;
    flex-direction: column;
  }
  .landline-name {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    padding-left: 24px;
    align-items: center;
    height: 88px;
  }
  .avatar-image {
    width: 88px;
    height: 88px;
    border: 1px solid $placeholder;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .avatar-landline-image {
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .avatar-text {
    line-height: 64px;
  }
  .landline-information {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    width: 300px;
    margin-right: 20px;
  }
  .landline-infotmation-text {
    color: $gray050;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
    display: flex;
  }
  .dots-icon {
    position: absolute;
    right: 18px;
    top: 30px;
  }
  .landline-info {
    flex-direction: row;
    padding-bottom: 36px;
    display: flex;
    align-items: center;
    flex: 2;
  }
  .landline-info-row {
    flex-direction: row;
    display: flex;
  }
  .landline-location-info {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 14px;
    line-height: 20px;
  }
  .thumbnail-wrapper {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .thumbnail-wrapper:hover {
    color: $blue;
  }
}
