@import "../../../colors.scss";

.driverShiftModal {
  .iconToDisplay {
    cursor: pointer;
  }
  .shiftModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: $darkOverlay;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    &__content {
      width: 312px;
      height: 340px;
      background-color: $white;
      border-radius: 4px;
      .shiftModal-inner_content {
        padding: 24px 16px 32px 16px;
        .modalTitle {
          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $darkLandline;
          }
        }
        .timeEditSection {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          .startTime,
          .endTime {
            .label {
              font-size: 14px;
              line-height: 20px;
              color: $gray050;
              margin-bottom: 8px;
            }
          }
          .timePickerInput-container {
            width: 132px;
            height: 32px;
            justify-content: space-between;
            background-color: $white;
            border: 1px solid $lightGray;
            .timePickerInput-abbreviation {
              width: 55;
              -webkit-appearance: "button";
              cursor: "pointer";
            }
            input,
            select {
              color: $darkLandline;
              background-color: $white;
              font-size: 14px;
              -moz-appearance: textfield;
              .option {
                background-color: $white;
                margin-top: 50px;
              }
            }
          }
        }
        .preferableShiftEditSection {
          .disabled {
            opacity: 0.5;
          }
          margin-top: 14px;
          .selectContainer {
            border: 1px solid $lightGray;
            border-radius: 8px;
            padding: 0 12px;
          }
          select {
            color: $darkLandline;
            background-color: $white;
            cursor: pointer;
            width: 100%;
            height: 32px;
            border: none;
            outline: none !important;
          }
          .label {
            font-size: 14px;
            line-height: 20px;
            color: $gray050;
            margin-bottom: 8px;
          }
        }
      }
      .offDutyInput {
        margin-top: 17px;
        display: flex;
        align-items: center;
        .checkbox-wrapper {
          cursor: pointer;
        }
        .label {
          font-size: 14px;
          line-height: 20px;
          color: $gray050;
          margin-left: 14.5px;
        }
      }
    }
  }

  .shiftTimeModal {
    .timePickerInput-abbreviation {
      width: 55;
      -webkit-appearance: "button";
      cursor: "pointer";
    }
  }
  .disabled {
    .timePickerInputs-wrapper {
      opacity: 0.5;
    }

    .timePickerInput-abbreviation {
      display: flex;
      input,
      select {
        opacity: 0.5;
      }
    }
  }

  &--actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    &__content {
      .btn-cancel {
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $darkLandline;
        background-color: $lightGray;
        padding: 8px 15.35px;
        border-radius: 8px;
        border: none;
        margin-right: 12px;
      }
      .btn-confirm {
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $darkLandline;
        background-color: $primaryLandline;
        padding: 8px 15.35px;
        border-radius: 8px;
        border: none;
        margin-right: 12px;
      }
    }
  }
}
