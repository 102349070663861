@import "../../../colors.scss";

.tab-container {
  width: max-content;
  position: relative;
  color: $gray050;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  margin-right: 40px;
  cursor: pointer;
  padding-bottom: 8px;
  font-weight: 600;
}
.tab-container:hover {
  color: $darkLandline;
}
.activeTab {
  font-weight: 600;
  color: $darkLandline;
  border-bottom: 1px solid $darkLandline;
}
.title-style {
  font-size: 20px;
}
.subtitle-style {
  font-size: 16px;
}
