@import "../../../colors.scss";

.pnr-item {
  margin-bottom: 12px;
  height: 64px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  justify-content: space-between;

  .pnr-text {
    min-width: 76px;
    font-size: 14px;
    font-weight: 400;
  }

  .remove-pnr-icon-wrapper {
    cursor: pointer;
  }

  .left-side-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .email-notification-popup {
    background-color: $white;
    position: fixed;
    padding: 6px 14px;
    box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    white-space: nowrap;
    transform: translate(calc(-50% + 10px), -110%);
  }

  .status-text {
    &.Sent {
      color: $primaryLandline;
    }

    &.Failed {
      color: $failedRed;
    }

    &.Pending {
      color: $pendingGray;
    }
  }

  .error-text {
    font-size: 12px;
    color: $failedRed;
  }

  .email-notification-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: calc(50% - 2px);
    bottom: -3px;
    border-top: 4px solid $white;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
