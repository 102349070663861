@import "../../../colors.scss";

.filter-dropdown-container {
  width: 120px;
  position: relative;
  height: 40px;
  display: flex;
  border: 1px solid $gray070;
  border-radius: 8px;
  margin-left: 14px;
  text-align: center;
  .activeFilterList {
    position: absolute;
    width: 312px;
    padding-bottom: 30px;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    background-color: $white;
    z-index: 2;
    top: 48px;
    left: -190px;
    max-height: 700px;
    overflow-y: auto;
  }
  .arrow-filter-wrapper {
    margin-left: 28px;
  }
  .filter-item {
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    width: auto;
  }
  .item:hover {
    background-color: $mediumGray;
  }
  .dropdown-button {
    align-items: center;
    height: 38px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  .label-filter-text {
    width: 64px;
    padding-left: 12px;
    font-size: 14px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .filter-name-container {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    flex: 1;
    cursor: pointer;
  }
  .filter-item-container {
    margin-top: 24px;
    padding: 0px 16px;
  }

  .filter-open {
    padding: 24px 16px;
    background-color: $gray005;
    margin-top: 0;
  }
  .filter-closed-next-open {
    margin-bottom: 24px;
  }
  .filter-open-next-open {
    border-bottom: 1px solid $lightGray;
  }
  .filter-checkbox-container {
    margin-right: 12px;
    cursor: pointer;
  }
  .filter-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .date-filter {
    width: 312;
    height: 172;
  }
  .clear-all-button-wrapper {
    flex: 1;
    text-align: right;
    margin-top: 24px;
    padding: 0px 16px;

    &.calendarFilters {
      margin-top: 50px;
    }
  }
  .clear-all-button {
    font-size: 14px;
    color: $blue;
    cursor: pointer;
  }
}
.filter-dropdown-calendar {
  margin-top: 24px;
}
.filters-radio-button {
  display: flex;
  flex-direction: row;
  width: 100px;
  cursor: pointer;
}
.radio-button-text {
  margin-left: 10px;
}
.filters-radio-button {
  margin-top: 24px;
}
