@import "../../../colors.scss";

.assign-driver-modal {
  position: relative;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    padding: "56px 50px 32px 50px";
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .driver-errors-wrapper {
    max-height: 430px;
    overflow-y: auto;
    padding: 5px 50px;
  }

  .driver-error {
    box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 24px 18px;
    display: flex;
  }

  .error-dot {
    margin-top: 6px;
    height: 10px;
    min-width: 10px;
    border-radius: 50%;
    background-color: $primaryLandline;
    margin-right: 10px;
  }

  .driver-error-text {
    font-size: 16px;
    line-height: 24px;
  }

  .buttons-container {
    display: flex;
    padding: 24px 50px 40px 50px;
    justify-content: flex-end;
  }
}
