.manifest-search-container {
    display: flex;
    padding: 1dvw;
    width: fit-content;

    input {
        margin-right: 1dvw;
        width: 200px!important;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.manifest-date-calendar {
    width: 265px;
}

table {
    width: 80%;
    margin: 1dvw;
    border-collapse: collapse;
}


th {
    text-align: left;
    padding: 0.5dvw;
}

td {
    padding: 0.5dvw;
}

.manifest-headers th {
    background-color: #ddd;
}

.manifest-headers .first-element {
    border-top-left-radius: 10px;
}

.manifest-headers .last-element {
    border-top-right-radius: 10px;
    width: 10%;
}

.manifest-passengers td {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
}

.manifest-passengers .first-element {
    border-left: 1px solid #CCC;
}

.manifest-passengers .last-element {
    border-right: 1px solid #CCC;
}

.flight-data-container {
    margin-top: 1dvw;
    margin-left: 1dvw;
    width: 40%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.leg-data-container {
    margin-top: 3dvw;
    margin-left: 1dvw;
    width: 40%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.flight-data-container div, .leg-data-container div {
    border-radius: 0.5dvw;
    margin-right: 0.5dvw;
    margin-bottom: 0.5dvw;
    padding: 0.5dvw;
}

.flight-data-container div {
    background-color: #333;
    color: white;
}

.leg-data-container div {
    background-color: #ddd;
}
