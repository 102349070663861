@import "../../../colors.scss";

.requests-dropdown-container {
  width: 110px;
  position: relative;
  min-height: 38px;
  display: flex;
  align-items: center;
  margin: 9px 32px 9px 16px;
  outline: none;
  cursor: pointer;
  .activeList {
    position: absolute;
    top: 40px;
    width: 229px;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  }
  .arrow-wrapper {
    position: relative;
    padding-top: 6px;
  }
  .item {
    height: 36px;
    width: 229px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .item:hover {
    background-color: $mediumGray;
  }
  .dropdown-button {
    align-items: center;
    height: 38px;
    display: flex;
    flex-direction: row;
  }
  .label-text {
    width: 80px;
    font-weight: 600;
    margin-right: 10px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $darkLandline;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .name-container {
    display: flex;
    flex-direction: row;
    width: 100px;
    align-self: center;
  }
}
