@import "../../../colors.scss";
.new-pending-requests-table-container {
  // TABLE
  .table-container {
    border-spacing: 0px 8px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 8px;
    .table-body-container {
      padding: 17px 0px;
      display: block;
      height: 256px;
      overflow-y: scroll;
      position: relative;
      margin: 0px 0px 0px -12px;
      padding-left: 12px;
    }
    .table-row-container {
      padding-left: 24px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns:
        minmax(187px, 1fr)
        minmax(110px, 1fr)
        minmax(128px, 1fr)
        minmax(111px, 1fr)
        minmax(116px, 1fr)
        minmax(116px, 1fr);

      border-bottom: 1px solid $mediumGray;
    }
  }
  // END OF TABLE

  //TABLE ROW
  .table-row-container {
    .td-container:first-child {
      padding-left: 24px;
    }
  }

  // END OF TABLE ROW

  // TABLE TD
  .row-item-container {
    display: grid;
    place-items: center left;
    height: 56px;
  }
  // END OF TABLE TD

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $lightGray;
    border-radius: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray040;
  }
}
