@import "../../../colors.scss";

.saved-note {
  background: $mediumGray;
  border-radius: 8px;
  padding: 16px 12px 12px 16px;
  margin-bottom: 25px;
  min-height: 198px;
  .saved-note_header {
    display: flex;
    justify-content: space-between;
    &-info {
      display: flex;
      align-items: center;
      &-middleDot {
        padding: 0 9px;
        color: $gray060;
      }
    }
  }
  .saved-note-content {
    background-color: $white;
  }

  .saved-note-content:disabled {
    background-color: $gray005;
  }

  &_header {
    margin-bottom: 12px;
    position: relative;

    img {
      border-radius: 50%;
      object-fit: cover;
    }

    .edit-note {
      cursor: pointer;
    }
  }

  &_name {
    color: $darkLandline;
    font-size: 12px;
    vertical-align: 8px;
    padding-left: 10px;
  }

  &_time {
    color: $gray050;
    font-size: 12px;
    vertical-align: 8px;
  }

  &-content {
    background: $white;
    width: 100%;
    height: 100px;
    border-radius: 4px;
    border: none;
    resize: vertical;
    padding: 10px;
    font-family: "Source Sans Pro";
    color: $lightBlack;
    line-height: 20px;
    font-size: 14px;
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    .delete-label {
      color: $alertError;
      vertical-align: 6px;
      cursor: pointer;
      font-size: 14px;
    }

    .send-label {
      color: $blue;
      vertical-align: 10px;
      cursor: pointer;
      padding-right: 8px;
      font-size: 14px;
    }
  }
}
