@import "../../../colors.scss";

.regular-layout-container {
  display: flex;
  &.isCollapsed .regular-layout-content{
    margin-left: 105px;
  }
  .regular-layout-content {
    transition: margin .3s linear;
    margin-left:260px;
    min-height: 100vh;
    width: 100%;

    &.withTopMenu {
      margin-top: 80px;
    }
  }
}

.regular-layout-with-header {
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 0px 16px 32px;
    align-items: center;
    border-bottom: 0.5px solid $placeholder;
    .header-text {
      font-weight: 600;
      font-size: 24px;
    }
  }
}
