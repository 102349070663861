.driver-card-container {
  text-align: center;
  max-width: 294px;
  min-width: 252px;
  height: 328px;
  background: #f5f5f5;
  box-shadow: 0px 0px 32px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  position: relative;
  h4 {
    font-weight: 600;
    size: 20px;
    line-height: 24px;
    margin: 8px;
  }
  .threeDot {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .footer {
    position: absolute;
    bottom: 0px;
    margin: 0 auto;
    width: 100%;
    background: #cdccc8;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: rgba(60, 56, 53, 1);
    border-radius: 0px 0px 8px 8px;
  }
  .link {
    position: absolute;
    left: 16px;
    top: 16px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(250, 181, 0, 1);
    line-height: 16px;
  }
}
