@import "../../../colors.scss";

.right-side-bar-container {
  .right-collapse-sidebar-container {
    color: $white;
    width: 100%;
    display: flex;
    padding-left: 24px;
  }
  .notifications {
    overflow: auto;
    position: fixed;
    bottom: 0;
    top: 490px;
    width: 336px;
    .loading-image {
      margin-top: 5px;
      margin-left: 155px;
    }
    .loading-label {
      color: $darkLandline;
    }
  }
  .notification-container {
    display: flex;
    flex-direction: row;
    margin: 35px 0 28px 24px;
    justify-content: space-between;
  }
  .mark-all-container {
    color: $markAllButton;
    font-size: 14px;
    padding-right: 25px;
    align-self: center;
    padding-top: 4px;
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  max-width: 336px;
  width: 100%;
  height: 100%;
  background: $white;
  position: fixed;
  top: 0;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 16px 0px 0px 0px;
  right: 0px;
}
.notificationWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dateContainer {
  width: 100%;
  padding-right: 30px;
  padding-top: 20px;
}

.dateText {
  text-align: right;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
}
.highlight-notifications {
  animation: blink 2s 1;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}
