@import "../../../colors.scss";

.cloud-upload-icon-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $alertInfoLight;
  border: 1px dashed $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
}

.upload-text {
  font-size: 12px;
  margin-bottom: 32px;
}

.upload-browse {
  color: $blue;
  font-weight: 600;
  cursor: pointer;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  pointer-events: none;
}
.custom-file-input {
  position: absolute;
  width: 80px;
  height: 80px;
}
.image-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.remove-image-icon-wrapper {
  position: absolute;
  left: 55px;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: $white;
}
