@import "../../../colors.scss";
.booking-request-modal-container {
  width: 344px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .dropdown-content {
    width: 344px;
    .infinite-scroll-table-dropdown {
      height: 155px;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 64px;
  }
  .request-to-operate-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }
  .request-to-operate-input-label {
    font-size: 12px;
    color: $gray070;
    margin-bottom: 8px;
  }
  .not-mandatory-text {
    font-size: 14px;
    line-height: 20px;
  }
  .reduce-rate-checkbox {
    margin-top: 24px;
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      padding-left: 11px;
      color: $gray080;
    }
  }
  .vehicle-label {
    margin-top: 24px;
  }
  .reduce-rate-checkbox-button {
    cursor: pointer;
  }
  .noVehicle {
    display: flex;
    svg {
      margin-right: 10px;
    }
  }
}
.vehicle-image-icon {
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
