@import "../../../colors.scss";

.login-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: $white;
  .login-page-left {
    flex: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      margin-bottom: 164px;
      max-width: 481px;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: $darkLandline;
    }
    h4 {
      margin-bottom: 56px;
    }
  }
  .form-error-container {
    padding-top: 24px;
    color: $red;
  }
  .login-page-right {
    overflow: hidden;
    flex: 6;
    background-color: $primaryLandline;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkboxForgotPWContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    label {
      filter: invert(65%) sepia(0%) saturate(0%) hue-rotate(87deg) brightness(94%) contrast(89%);
    }
  }
}
.form-input {
  display: grid;
  position: relative;
}
.form-input-toggleBtn {
  position: absolute;
  top: 50px;
  right: 25px;
  cursor: pointer;
}
