@import "./colors.scss";
html {
  overflow: visible;
  overflow-y: scroll;
}
h2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
}
h4 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.label {
  font-size: 12px;
  line-height: 16px;
}
.body1-semibold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.body2 {
  font-size: 14px;
  line-height: 20px;
}
.body2-semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
a {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $alertInfoBlue;
  text-decoration: none;
}
input {
  color: $black !important;
}
.btn {
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
