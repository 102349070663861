@import "../../../colors.scss";

.sidebar-menu-item {
  transition: width 0.3s linear;
  max-width: 176px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 14px;
  text-decoration: none;
  border-radius: 8px;
  height: 54px;

  svg {
    min-width: 20px;
  }

  .menu-item-title {
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin-bottom: 8px;
  &.openCollapse {
    width: 48px;
  }
}

