@import "../../../colors.scss";

.table-two-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .second-row {
    color: $gray050;
  }
}
