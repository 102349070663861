@import "../../../colors.scss";

.avatar-vehicle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 50%;
    object-fit: cover;
  }
  span {
    margin-left: 8px;
  }
}
