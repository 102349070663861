@import "../../../colors.scss";

.tablePopup-wrapper {
  position: relative;
  .tablePopup-content > * {
    padding: 0 !important;
  }
  .popup {
    position: absolute;
    background-color: $white;
    top: 60px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
    color: $black;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    &.withIcon {
      p {
        padding-left: 12px;
      }
    }
  }
}
