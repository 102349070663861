@import "../../../colors.scss";

.page-404-container {
  display: flex;
  min-height: 100vh;
  background-color: $white;

  img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}
