@import "../../../colors.scss";

.background-container {
  background-color: $primaryLandline;
  border-radius: 8px;
  width: 288px;
  height: 246px;
}
h5 {
  font-size: 24px;
  color: $darkLandline;
  line-height: 32px;
  font-weight: 600;
}
.last24hoursContainer {
  display: flex;
  margin-top: 54px;
  padding-bottom: 24px;
}
.bookingContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.bookingInfo {
  display: flex;
  font-size: 64px;
  color: $darkLandline;
  width: 68;
  height: 64;
  font-weight: bold;
  align-items: center;
  margin-left: 16px;
  margin-top: 24px;
}
.bookingText {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $darkLandline;
  width: 68px;
  height: 40px;
  margin-top: 43px;
  margin-left: 9px;
}
.containerBox {
  display: flex;
  flex-direction: row;
}
.dataContainer {
  display: flex;
  flex-direction: row;
  background-color: $darkLandline;
  width: 126px;
  height: 42px;
  border-radius: 8px;
  margin-left: 14px;
  margin-top: 37px;
  margin-bottom: 11px;
  text-align: center;
  padding-left: 8px;
  cursor: pointer;

  &.second-item {
    margin-left: 8px;
  }

  &.second-row {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
.dataContainer-cr {
  background-color: $darkLandline;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  margin-left: 16px;
  margin-top: 62px;
  margin-bottom: 24px;
  padding-top: 8px;
  cursor: pointer;
}
.dataInfo {
  font-size: 28px;
  color: $white;
}
.dataInfo-cr {
  color: $white;
  margin-left: 8px;
}
.textInfo {
  color: $white;
  font-size: 12px;
  padding: 15px 0px 0px 8px;
}
.textInfo-cr {
  color: $white;
  font-size: 12px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.newBookingText {
  color: $white;
  font-size: 12px;
  line-height: 14px;
  padding: 7px 0px 0px 8px;
  width: 20px;
}
