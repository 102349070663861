@import "../../../colors.scss";

.google-map-container {
  height: 50vh;
  margin-bottom: 20px;
}

.google-map-point {
  color: $darkLandline;
  font-weight: bold;
  background: $primaryLandline;
  border: 2px solid $darkLandline;
  padding: 5px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
