@import "../../../colors.scss";

.driverLimitationsCard {
  width: 117px;
  height: 184px;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  display: grid;
  place-items: center;
  position: relative;
  &__content {
    .limit--title {
      color: $gray050;
      font-weight: 600;
    }
    .limit--duration {
      color: $darkLandline;
      font-weight: 600;
      font-size: 18px;
    }
    text-align: center;
    padding: 36px 0 32px 0;
    .limitation--icon {
      padding: 20px 0 20px 0;
      display: grid;
      place-items: center;
    }
  }
  .editButton {
    position: absolute;
    top: 11px;
    right: 15px;
    cursor: pointer;
  }
  .driverLimitationsModal {
    position: fixed;
    background-color: $darkOverlay;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: grid;
    place-items: center;
    &__content {
      width: 240px;
      // height: 206px;
      background-color: $white;
      border-radius: 4px;
      &--innerContent {
        padding: 19.6px 16px 27px 16px;

        .exitButton {
          display: flex;
          justify-content: flex-end;
          svg {
            cursor: pointer;
          }
        }
        .modalTitle {
          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $darkLandline;
          }
        }
        .editInputWrapper {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            font-size: 14px;
            line-height: 20px;
            color: $gray050;
          }
          .timeFormat {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $darkLandline;
          }
          .editInputBox {
            width: 99px;
            height: 32px;
            background: $white;
            border: 1px solid $lightGray;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            .spinButtons {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              padding-right: 13.55px;
              .buttonHolder {
                cursor: pointer;
                margin-top: -5px;
              }
            }
          }
          input {
            border: none;
            width: 60px;
            height: 30px;
            margin-left: 5px;
            outline: none;
            font-size: 14px;
            -moz-appearance: textfield;
          }
        }
        .formWarning {
          padding-top: 5px;
          color: $alertError;
          text-align: left;
        }
        .editModalActions {
          margin-top: 32px;
          display: flex;
          justify-content: flex-end;
          .btn-cancel {
            cursor: pointer;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $darkLandline;
            background-color: $lightGray;
            padding: 8px 15.35px;
            border-radius: 8px;
            border: none;
            margin-right: 12px;
          }
          .btn-confirm {
            cursor: pointer;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: $darkLandline;
            background-color: $primaryLandline;
            padding: 8px 15.35px;
            border-radius: 8px;
            border: none;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
