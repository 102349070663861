@import "../../../colors.scss";

.vehicle-card-container {
  width: 100%;
  height: 64px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  margin-bottom: 8px;
  align-items: center;

  .fields-container {
    position: relative;
    flex-direction: row;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 100px;

    .icon-wrapper {
      width: 16px;
      position: absolute;
      right: 0;
      top: 8px;
    }

    .field {
      font-size: 16px;
      font-style: normal;
      color: $darkLandline;
      display: flex;
      width: 100px;
      align-items: center;
      font-weight: 400;
    }

    .avatar {
      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .model {
      width: 180px;
    }
    .id {
      font-weight: 600;
    }
    .field-wrapper {
      text-align: center;
      padding: 0px 8px;
      font-size: 12px;
      height: 24px;
      border-radius: 8px;
      background-color: $successGreen;
      color: $white;
      font-style: normal;
      width: 96px;
    }

    .secondary {
      background-color: $lightGray;
      color: $darkLandline;
    }
  }
}
