@import "../../../colors.scss";
.passwordResetPage {
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
  &--backgroundSplit {
    display: flex;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    .whiteBox {
      background-color: $white;
      width: 60%;
      height: 100%;
    }
    .yellowBox {
      background-color: $primaryLandline;
      width: 40%;
      height: 100%;
    }
  }
  &__content {
    width: 1068px;
    height: 834px;
    background-color: $white;
    display: grid;
    place-items: center;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    &--innerContent {
      width: 481px;
      .pageTitle {
        margin-top: 15px;
        font-size: 24px;
        color: $darkLandline;
      }
      .passwordResetPage--form {
        margin-top: 40px;
        .input__wrapper {
          margin-top: 15px;
          .label {
            font-size: 12px;
            color: $gray070;
            padding: 0 0 12px 0;
          }
          .inputBox {
            display: flex;
            align-items: center;
            position: relative;
            input {
              width: 100%;
              padding: 11px 12px;
              border-radius: 8px;
              border: 1px solid $lightGray;
              outline: none;
            }
            .showPassword {
              cursor: pointer;
              position: absolute;
              right: 15px;
              bottom: 10px;
            }
          }
        }
        .submitButton {
          padding: 11px 0;
          margin-top: 24px;
          width: 100%;
          border: none;
          border-radius: 8px;
          background-color: $darkLandline;
          color: $white;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
  .passwordError {
    margin-top: 10px;
    color: $alertError;
    font-size: 14px;
  }
}
