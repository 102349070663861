@import "../../../colors.scss";

.color-picker-container {
  margin-top: 20px;

  .color-picker-label {
    font-size: 12px;
    margin-bottom: 8px;
    color: $gray070;
  }

  .color-picker-input {
    width: 330px;
    height: 40px;
    background-color: $mediumGray;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }

  .color-preview {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 15px;
    border-radius: 2px;
  }

  .chrome-picker {
    width: 330px !important;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 10;
  }
}
