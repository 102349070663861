@import "../../../colors.scss";

.operators-page-container {
  .TextInput {
    background-color: $white;
    height: 120px;
    width: auto;
    padding: 15px 15px;
  }
}
.operators-container {
  flex-direction: row;
  display: flex;
  flex: 1;
  padding: 18px 0px 16px 32px;
  border-bottom: 0.5px solid $placeholder;
  justify-content: space-between;
  align-items: center;
}
.operators-text {
  font-weight: 600;
  font-size: 24px;
}
.input-container {
  margin-left: 32px;
  margin-top: 56px;
}
.all-companies-container {
  flex-direction: row;
  display: flex;
  padding: 0 32px 32px 32px;
  flex-wrap: wrap;
}
