@import "../../../colors.scss";

.operators-profile-page-container {
  cursor: default;
  padding-bottom: 32px;
  .operators-page-container {
    .TextInput {
      background-color: $white;
      height: 120px;
      width: auto;
      padding: 15px 15px;
    }
  }
}
.profile-page-tab-container {
  margin-top: 56px;
  margin-left: 32px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-right: 32px;
  .icon-wrapper {
    cursor: pointer;
  }
}
.list-of-representatives {
  font-size: 12px;
  line-height: 16px;
  padding-top: 56px;
  font-weight: normal;
  color: $darkLandline;
  letter-spacing: 0.02em;
  padding-bottom: 24px;
}

.loading-operators {
  margin-top: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  .loading-label {
    color: $black;
  }
}
