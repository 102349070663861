@import "../../../colors.scss";

.driver-edit-modal-container {
  padding-top: 68px;
  .personal-info-container {
    font-weight: 600;
  }
  .info-title-container {
    color: $gray070;
    font-size: 12px;
    padding-top: 16px;
    padding-bottom: 8px;
    .input-wrapper {
      margin-top: 8px;
    }
  }
  .info-container {
    padding-top: 32px;
    font-weight: 600;
    padding-bottom: 16px;
  }
  .choose-vehicle-container {
    color: $gray070;
    font-size: 12px;
    padding-bottom: 20px;
  }
  .vehicle-container {
    display: flex;
    flex-direction: row;
  }
  .vehicle-title {
    padding-left: 16px;
    padding-right: 28px;
  }
  .add-note-container {
    color: $gray070;
    font-size: 12px;
    padding-left: 2px;
  }
  .start-end-date-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
  }

  .driver-modal-calendar-container {
    width: auto;
  }

  .checkbox-wrapper {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }
  .dropdown-status-style {
    background-color: $mediumGray;
    border: none;
  }
  .label-location {
    padding-top: 8px;
  }
  .location-style {
    padding-top: 8px;
    line-height: 16px;
  }
}
.close-icon-style {
  right: 0;
  position: absolute;
  cursor: pointer;
}
.error-date {
  font-size: 12px;
  color: $red;
}
