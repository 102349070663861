.TableHead-container {
  position: relative;
  text-align: left;
  margin-top: 40px;

  .table-head-checkbox {
    position: absolute;
    left: -25px;
    top: 0px;
    cursor: pointer;
  }
}
