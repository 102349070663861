.label-type-container {
  background-color:rgba(229, 229, 229, 1);;
  padding: 5px;
  text-align: center;
  border-radius: 18px;
  color: white;
  max-width: 60px;
}
.activeClass{
  background-color:#10c492;
  padding: 5px;
  text-align: center;
  border-radius: 18px;
  color: white;
  max-width: 60px;
}
