@import "../../../colors.scss";

.list-of-representatives {
  font-size: 12px;
  line-height: 16px;
  padding-top: 56px;
  font-weight: normal;
  color: $darkLandline;
  letter-spacing: 0.02em;
  padding-bottom: 24px;
}
.company-input-container {
  margin: 56px 0;
}
