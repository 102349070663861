.collapsible-container {
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  .toggle-container {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
}
