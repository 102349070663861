@import "../../../colors.scss";

.status-modal {
  background-color: $gray005;
  padding: 24px 16px;
  width: 312px;
  height: 822px;
}

.status-confirmation-modal {
  background-color: $white;
  width: 392px;
  height: 609px;

  .affected-events-container {
    height: 368px;
    overflow-y: auto;
    padding: 5px 40px 5px 50px;
  }

  .affected-event-modal-text {
    padding: 40px 50px 32px 50px;
  }

  .affected-event {
    box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
    border-radius: 8px;
    padding: 16px;
    height: 64px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .event-dot {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.driver-status-label {
  font-size: 14px;
  color: $gray050;
  margin-bottom: 8px;
}

.description-input {
  height: 96px;
  width: 100%;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.start-end-time-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.driver-status-calendar-container {
  margin: 16px 0px;
}

.driver-status-buttons-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.confirmation {
    padding-right: 50px;
  }
}

.event-details-error-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.event-details-error {
  position: absolute;
  font-size: 12px;
  color: $red;
  bottom: -4px;
  overflow: visible;
  white-space: nowrap;
}
