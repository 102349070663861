.item-for-driver-card-container {
    position: absolute;
    bottom: 65px;
  .assignedTo {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 2%;
    color: rgba(153, 153, 153, 1);
    margin: 0px 0px 0px 16px;
  }
  .city{
      margin: auto;
      padding: 5px;
      font-weight: 600;
      size: 14px;
      color: rgba(60, 56, 53, 1);
  }
  img{
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin: 0px;
  }
  .wrap{
      display: flex;
      margin-left: 16px;
      margin-top: 8px;
      
      background: #f5f5f5;
  }
  .wrapCity{
      display: flex;
      background: #f5f5f5;
      box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.1);
      margin-left: 8px;
      border-radius: 20px;
      padding: 0 5px;
  }
  .dotIcon{
    margin: auto;
  }
  .tripNumber{
      margin: auto;
      font-weight: 600;
      font-size: 14px;
      color: rgba(128, 128, 128, 1);
  }
}
