@import "../../../colors.scss";

.add-vehicle-modal {
  width: 420px;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 32px;
  }

  .close-button-wrapper {
    align-self: center;
    cursor: pointer;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .field-label {
    font-size: 12px;
    color: $gray070;
  }

  .text-input-wrapper {
    margin-bottom: 24px;
  }

  .input-pair {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 16px;
    justify-content: space-between;
    .first-input-field {
      width: 244px;
    }
    .second-input-field {
      width: 152px;
    }
  }

  .buttons-wrapper {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    margin-top: 56px;
  }
}
