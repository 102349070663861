@import "../../../colors.scss";

.table-title-wrapper {
  display: block;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
}
.table-container {
  width: 100%;
  background-color: $white;
}
