@import "../../../colors.scss";

.THeadItem-container {
  flex: 1;
  color: $gray050;
  flex-direction: row;
}
.THeadItem-title-wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.sorting-popup-list {
  position: absolute;
  background-color: $white;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  z-index: 9999;
  margin-top: 2px;
}
.sorting-popup-item {
  color: $darkLandline;
  text-align: center;
  padding: 7px 0px;
  width: 110px;
  height: 30px;
  cursor: pointer;
  font-size: 14px;
  text-transform: none;
  &:hover {
    background-color: $mediumGray;
  }
}
