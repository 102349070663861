@import "../../../colors.scss";

.dropdown {
  label {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    color: $gray070;
  }
  .mandatory-star {
    color: $primaryLandline;
  }
  .unassignedProfile {
    p {
      padding-left: 6px;
    }
  }
}

.dropdown-container {
  width: 100%;
  position: relative;
  min-height: 38px;
  border: 1px solid $lightGray;
  display: flex;
  align-items: center;
  padding: 9px 32px 9px 12px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  &.filter-dropdown {
    margin-top: 24px;
    min-height: 32px;
    height: 32px;
    background-color: white;
  }

  &.driver-status-dropdown {
    width: 100%;
    min-height: 32px;
    height: 32px;
    background-color: $white;
  }

  &.settings-style {
    min-height: 40px;
    height: 40px;
    background-color: $mediumGray;
    border: 0px;
  }

  &.withSearch {
    padding: 0;
    border: none;
  }

  .placeholder {
    color: $gray050;
  }
  .activeList {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  }
  .activeListScrollable {
    max-height: 150px;
    overflow-y: auto;
  }
  .item {
    padding: 7px 0px 7px 15px;
    align-items: center;
    display: flex;
  }
  .arrow-wrapper {
    position: absolute;
    line-height: 0px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .item:hover {
    background-color: $lightGray;
  }
  .empty-state-text {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 6px 0;
  }
}
