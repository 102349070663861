@import "../../../colors.scss";

.trip-details-modal-container {
  position: relative;
  padding-bottom: 5px;

  .loading {
    display: inline-block;
    visibility: hidden;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
  }

  .pnr-container {
    width: 100%;
    text-align: center;
    padding-top: 56px;
    margin-bottom: 30px;
  }
  .input-wrapper {
    margin-bottom: 16px;
  }
  .text-input-pair {
    .input-wrapper {
      margin-bottom: 0px !important;
    }
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    .input-wrapper:first-child {
      margin-right: 16px;
      flex: 2;
    }
    .input-wrapper:last-child {
      flex: 1;
    }
  }
  .reversed {
    .input-wrapper:first-child {
      margin-right: 16px;
      flex: 1;
    }
    .input-wrapper:last-child {
      flex: 2;
    }
  }
  .date-input-pair {
    .input-wrapper {
      margin-bottom: 0px !important;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
    .input-wrapper:first-child {
      margin-right: 16px;
      flex: 2;
    }
    .input-wrapper:last-child {
      flex: 1;
    }

    &.warning {
      margin-bottom: 24px;
    }

    .date-warning {
      font-size: 11px;
      color: $red;
      position: absolute;
      bottom: -16px;
      height: 16px;

      &.flight {
        bottom: -13px;
      }
    }
  }
  .reversed {
    .input-wrapper:first-child {
      margin-right: 16px;
      flex: 1;
    }
    .input-wrapper:last-child {
      flex: 2;
    }
  }
  .number-of-passengers-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    input {
      margin-left: 16px;
      width: 38px;
      padding: 0px;
      justify-content: center;
      text-align: center;
    }
    .input-wrapper {
      margin: 0px;
    }
  }
}

.trip-not-found {
  font-size: 20px;
  font-weight: 600;
}

.trip-details-calendar-container {
  width: auto;
}
.trip-details-modal-container__exitBtn {
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.trip-way-icon {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: $mediumGray;
  margin: 0 auto;
  display: grid;
  place-items: center;
  svg {
    fill: $darkLandline !important;
    width: 16px;
    height: 16px;
  }

  &.toggle-enabled {
    cursor: pointer;
  }
}
.trip-way-text {
  font-size: 14px;
  color: $gray050;
}
.switch-leg-tooltip {
  position: absolute;
  left: -95px;
  background-color: $white;
  box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
  border-radius: 4px;
  font-size: 12px;
  width: 84px;
  height: 24px;
}
.switch-leg-tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  top: 9px;
  right: -4px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid $white;
}
.date-of-booking-text {
  padding-top: 6px;
  margin-bottom: 54px;
  line-height: 20px;
  font-size: 14px;
}
.address-confirmation-modal-container {
  position: relative;
  padding: 32px 24px 24px 24px;

  .address-confirmation-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 32px;
  }

  .address-confirmation-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
