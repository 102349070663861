@import "../../../colors.scss";
.passwordRecovery {
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  place-items: center;
  &--backgroundSplit {
    display: flex;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    .whiteBox {
      background-color: $white;
      width: 60%;
      height: 100%;
    }
    .yellowBox {
      background-color: $primaryLandline;
      width: 40%;
      height: 100%;
    }
  }
  &__content {
    width: 1068px;
    height: 834px;
    background-color: $white;
    display: grid;
    place-items: center;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    &--innerContent {
      width: 481px;
      .pageTitle {
        margin-top: 9px;
        font-size: 24px;
        font-weight: 600;
        color: $darkLandline;
      }
      .illustration {
        margin-top: 30px;
        text-align: center;
      }
      .subText {
        margin-top: 30px;
        font-size: 18px;
        color: $gray070;
      }
      .recoveryActions {
        margin-top: 24px;
        .label {
          font-size: 12px;
          color: $gray070;
          padding: 0 0 12px 0;
        }
        input {
          width: 100%;
          padding: 11px 12px;
          border-radius: 8px;
          border: 1px solid $lightGray;
          outline: none;
        }
        .wrongEmailError {
          margin-top: 10px;
          color: $alertError;
          font-size: 14px;
        }
        .continueBtn {
          margin-top: 24px;
          width: 100%;
          background-color: $darkLandline;
          color: $white;
          padding: 11px 0;
          font-size: 14px;
          letter-spacing: 0.04em;
          font-weight: 600;
          border-radius: 8px;
          border: none;
          cursor: pointer;
        }
      }
      .backBtnWrapper {
        margin-top: 18px;
        .backBtn {
          font-weight: 700;
          font-size: 16px;
          color: $gray070;
          cursor: pointer;
        }
      }
      .emailSent {
        text-align: center;
        font-size: 18px;
        color: $gray070;
        img {
          margin-top: 66px;
          margin-bottom: 41px;
        }
        p {
          padding: 8px 0;
          span {
            color: $primaryLandline;
          }
        }
        .backToLogin__wrapper {
          margin-top: 30px;
        }
        .backToLoginBtn {
          border: none;
          background-color: $darkLandline;
          padding: 11px 34px;
          border-radius: 8px;
          color: $white;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
