@import "../../../colors.scss";

.driverTransportationStatistic {
  margin-right: 20px;
  background-color: $white;
  width: 297px;
  min-width: 297px;
  height: 386px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 16px;
  &__content {
    padding: 40px 40px 36px 40px;

    .transportationNavigation {
      margin: 0 auto;
      width: 180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .vehicleTypeName {
        font-weight: 600;
        color: $darkLandline;
      }
      .arrowLeft,
      .arrowRight {
        padding: 0 10px;
        cursor: pointer;
      }
    }

    .transportationDirection {
      margin-top: 39px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .transportation--icon--container {
        position: relative;
      }
      .transportation-icon {
        cursor: pointer;
      }
      .transportation-icon-rotate {
        transform: rotateY(180deg);
      }
      .destinationRight {
        transform: rotateY(180deg);
      }
    }
    .transportationStatsTable {
      line-height: 20px;
      margin-top: 25px;
      &__content {
        .tableHeadRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 96%;
          p {
            color: $gray050;
            font-size: 14px;
          }
        }
        .tableBody {
          margin-top: 4px;
          .row {
            display: flex;
            justify-content: space-between;

            align-items: center;
            font-size: 14px;
            padding-top: 12px;

            .ridesDestination {
              color: $gray050;
            }
            .ridesCount {
              color: $darkLandline;
            }
          }
        }
      }
    }
  }
}
.transportation-empty-state {
  padding-top: 54px;
  padding-left: 24px;
}
.transportation-loading {
  padding-left: 90px;
  padding-top: 65px;
}
