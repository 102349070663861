@import "../../../colors.scss";
.restriction-popup {
  width: 318px;
  p {
    color: $darkLandline;
    font-size: 16px;
    padding: 24px;
  }
}

.openOnTop {
  transform: translate(0, -100%);
}
