@import "../../../colors.scss";

.settings-container {
  flex-direction: row;
  display: flex;
  flex: 1;
  padding: 0px 0px 0px 32px;
  border-bottom: 0.5px solid $placeholder;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 4px;
  background-color: $white;

  .title-text {
    font-weight: 600;
    font-size: 24px;
  }
}

.tab-layout-gray-bg {
  padding-top: 48px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: $mediumGray;
}

.settings-tab-container {
  background-color: unset !important;
}
