@import "../../../colors.scss";

.active-filter-container {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  height: 32px;
  padding: 0 12px;
  background-color: $filterGray;
  border: 1px solid $lightGray;
  border-radius: 8px;
  align-items: center;
  margin-left: 8px;

  .active-filter-text {
    font-size: 14px;
    margin-right: 10px;
  }

  .close-icon-wrapper {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
