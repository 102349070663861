$black: #000;
$white: #fff;
$placeholder: #cdccc8;
$darkLandline: #3c3835;
$lightBlack: #28221e;
$mediumGray: #f5f5f5;
$darkGray: #696d6f;
$lightGray: #e5e5e5;
$primaryLandline: #fab500;
$primaryLandlineDisabled: #ffe8ad;
$loyaltyLandline: #ff9800;
$red: #ff4040;
$lightBeige: #ffe6a6;
$darkGrayBorder: #bebebe;
$mediumBlack: #2c2c2c;
$lightBlackBorder: #3f3835;
$lightGreen: #b0eadb;
$successGreen: #5cbb69;
$green: #00c08b;
$lightBlue: #bbebff;
$lightPurple: #c7beff;
$borderGrayPicker: #c4c4c4;
$linkUnderline: blue;
$blue: #2278b5;
$darkOverlay: rgba(58, 58, 58, 0.253);
$hoverGreen: #e2f3e5;
$canceledRed: #ffd7d1;
$markAllButton: #006bc9;
$filterGray: #f7f7f7;
$mediumGreen: #67d19e;
$mediumBlue: #69bdec;
$failedRed: #d93d38;
$pendingGray: #858a8d;

// COMPANY COLORS
$videcomBg: #eff6fb;
$videcomBorder: #aed1ea;
$videcomInnerBtnColor: #e7f1f9;
$videcomBtnHover: #bdd8ee;
$sunCountryBg: #fafafa;
$sunCountryBorder: #808080;
$sunCountryInnerBtnColor: #f5f5f5;
$cancelledInnerBtnColor: #ffedeb;

$gray005: #fafafa;
$gray010: #f5f5f5;
$gray060: #808080;
$gray050: #999999;
$gray040: #b3b3b3;
$gray070: #666666;
$gray080: #4d4d4d;
$gray090: #808080;
$gray100: #dedede;

$primaryLandlineDisabledGray: #a9a29e;
$alertBorderRed: #fda79b;
$alertLightRed: #fff6f5;
$alertInfoBlue: #3389c7;
$alertSuccessGreen: #e8f8ee;
$alertSuccessBorderGreen: #b2e6c7;
$alertInfoLight: #f7fafd;
$alertError: #e35c55;
$alertErrorBackground: #ffedeb;
$alertInfoBackgrond: #e7f1f9;
$borderWarning: #fdb2a8;
