@import "../../../colors.scss";

.statusTableCell-container {
  position: relative;
  .statusTableCell-inner-container {
    font-size: 12px;
    line-height: 16px;
    width: 96px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    svg {
      margin-right: 4px !important;
    }
    &.Unassigned {
      background-color: $mediumGray;
      border: 0.5px solid $lightGray;
    }
    &.Suggested {
      background-color: $mediumGray;
      border: 0.5px solid $lightGray;
    }
    &.Pending {
      background-color: $white;
      border: 0.5px solid $gray070;
    }
    &.Approved {
      background-color: $white;
      border: 0.5px solid $successGreen;
      color: $successGreen;
    }
    &.Cancelled {
      background-color: $white;
      border: 0.5px solid $alertError;
      color: $alertError;
    }
    &.Denied {
      svg {
        fill: $alertError !important;
      }
      color: $alertError;
      background-color: $white;
      border: 0.5px solid $alertError;
    }
    &.New {
      background-color: $white;
      border: 0.5px solid $loyaltyLandline;
      color: $loyaltyLandline;
    }
  }
  .missing-info-container {
    position: absolute;
    left: -10px;
    top: -0px;
    width: 22px;
    height: 22px;
    background-color: $white;
    border-radius: 100%;
    box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testing-box {
    position: absolute;
    left: -25px;
    align-self: center;
    padding-top: 2px;
  }
}
