.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

.car-animation-wrapper {
  width: 100%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }
}
.car-animation-content {
  margin: 0 auto;
  width: 438px;
  height: auto;
  position: relative;
  animation-name: composition;
  animation-duration: 4s;
}
.animationItem {
  position: absolute;
}
.animationItem-tire1 {
  width: 80px;
  height: 80px;
  top: 92px;
  left: 40px;

  animation-name: tire1;
  animation-duration: 4s;
}
.animationItem-tire2 {
  width: 80px;
  height: 80px;
  top: 92px;
  left: 300px;
  animation-name: tire1;
  animation-duration: 4s;
}
.animationItem-shade {
  top: 168px;
}

//  TIRE 1
@keyframes tire1 {
  0% {
    transform: rotateZ(560deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
// TIRE 2
@keyframes tire2 {
  0% {
    transform: rotateZ(560deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
// composition
@keyframes composition {
  0% {
    left: 600px;
  }
  100% {
    left: 0;
  }
}
