@import "../../../colors.scss";

.additional-trip-info-container {
  background-color: $white;
  width: 220px;
  position: absolute;
  left: -40px;
  top: 18px;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  padding: 24px 22px 20px 22px;
  cursor: default;

  &.show-on-top {
    top: unset;
    bottom: 16px;
  }

  &.show-on-top:before {
    top: unset;
    bottom: -6px;

    border-bottom: unset;
    border-top: 6px solid $white;
  }

  .title-wraper {
    font-size: 14px;
    font-weight: 400;
    color: $darkLandline;
    cursor: pointer;

    &.event-type {
      margin-top: 14px;
      cursor: default;
    }
  }
  .date-time-label {
    font-size: 12px;
    color: $gray050;
    height: 12px;
    font-weight: 400;
  }
  .date-time-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .date-time-value {
    position: relative;
    font-size: 12px;
    color: $darkLandline;
    padding-top: 4px;
    font-weight: 400;
  }
  .left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .cancel-button-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .additional-day-text {
    top: 18px;
    font-size: 12px;
    color: $primaryLandline;
    position: absolute;
  }
}

.additional-trip-info-container:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-bottom: 6px solid $white;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  left: 24px;
  top: -6px;
}

.non-drive-details {
  .non-drive-details-label {
    font-size: 12px;
    color: $gray050;
    height: 12px;
    font-weight: 400;
    text-align: left;
    margin-top: 16px;
  }

  .letter-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $primaryLandline;
    font-size: 12px;
    padding: 6px 0px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    margin-top: 12px;

    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .creator-name {
    font-size: 14px;
    margin-left: 8px;
    padding-top: 5px;
  }

  .creation-date-time {
    font-size: 12px;
    color: $darkLandline;
    margin: 0px 12px 0px 5px;
    padding-top: 1px;
  }

  .description-wrapper {
    position: relative;
  }

  .description-text {
    margin-top: 12px;
    font-size: 12px;
    text-align: left;
    display: block;
    display: -webkit-box;
    line-height: 1.4;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.description-popup {
  background-color: $white;
  width: 150px;
  position: absolute;
  left: -164px;
  bottom: -10px;
  z-index: 10000;
  border-radius: 4px;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  padding: 10px;
  cursor: default;
  text-align: left;
  word-wrap: break-word;

  &.show-on-right {
    left: unset;
    right: -164px;
  }

  &.show-on-right:before {
    right: unset;
    border-left: unset;
    left: -8px;
    border-right: 8px solid $white;
  }
}

.description-popup:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid $white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  right: -8px;
  bottom: 42px;
}
