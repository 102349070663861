@import "../../../colors.scss";

.pie-chart-tab-wrapper {
  display: flex;
  flex-direction: row;
  width: 400px;

  .pie-chart-label-wrapper {
    padding-top: 29px;
  }

  .pie-chart-label-item {
    display: flex;
    flex-direction: row;
  }

  .pie-chart-label-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 1px;
    margin: 1px 8px 18px 0px;
  }

  .pie-chart-label-text {
    font-size: 14px;
    color: $gray050;
  }
}
