@import "../../../colors.scss";
.table-row-container {
}

.activeNotification {
  animation: blinker 2s linear 1;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
    background: $loyaltyLandline;
  }
}
