.card-section-container {
  h4 {
    font-weight: 600;
    color: rgba(153, 153, 153, 1);
  }
  img {
    height: 216px;
    width: 25%;
    background-color: lightgray;
    margin: 18px;
    border-radius: 8px;
    min-width: 150px;
  }

  .wrap {
    background-color: white;
    border-radius: 8px;
    display: flex;
    padding: 10px;
    min-width: 400px;
    max-width: 915px;
  }
  .firstWrap {
    width: 100%;
    margin-left: 24px;
  }
  .secondWrap {
    p {
      font-weight: 600;
      font-size: 32px;
      margin: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .thirdWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }
}
