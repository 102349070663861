@import "../../../colors.scss";

.driver-event-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  .driver-event-dot {
    margin-right: 6px;
    margin-top: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $mediumGreen;
    min-width: 6px;
  }

  .driver-event-text-wrapper {
    cursor: pointer;
    position: relative;
    width: 100%;
  }

  .driver-event-text {
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  .additional-day-popup {
    font-size: 12px;
    position: absolute;
    overflow: visible;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
    padding: 4px 9px;
    top: -4px;
    white-space: nowrap;
    z-index: 10;
  }
}

.cancel-event-container {
  .buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
  }
}
