@import "../../../colors.scss";

.row-preview-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 30px;
  align-items: center;
  height: 36px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  column-gap: 20px;
  padding: 0px 15px 0px 10px;
  border-radius: 8px;
  font-size: 9px;
  width: 710px;

  .status-wrapper {
    font-size: 9px;
    line-height: 16px;
    width: 58px;
    padding: 4px 4px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $white;
    border: 0.5px solid $successGreen;
    color: $successGreen;
  }

  .item-wrapper {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
  }

  .dropdown-wrapper {
    display: flex;
    flex-direction: row;
    height: 20px;
    background-color: $mediumGray;
    padding: 4px 8px;
    border-radius: 4px;
    align-items: center;
    white-space: nowrap;
  }

  .row-text {
    color: $darkLandline;
  }

  .whiteFont {
    color: $white;
  }
}
