@import "../../../colors.scss";

.change-app-settings-container {
  padding: 40px 40px;
  background-color: $white;
  margin-top: 16px;

  .color-title-wrapper {
    display: flex;
    flex-direction: row;
    width: 330px;
    justify-content: space-between;
    align-items: center;

    &.top-margin {
      margin-top: 64px;
    }
  }

  .color-title {
    font-size: 16px;
  }

  .buttons-wrapper {
    margin-top: 114px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .icon-wrapper {
    position: relative;
    cursor: pointer;
  }

  .preview-popup {
    background-color: $white;
    position: absolute;
    top: -30px;
    left: -32px;
    padding: 6px 23px;
    font-size: 12px;
    box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
    border-radius: 4px;
  }

  .preview-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 6px solid $white;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    top: 22px;
    left: 36px;
  }
}
