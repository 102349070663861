@import "../../../colors.scss";

.mixed-pnr-content-container {
  background-color: $white;
  .title-wraper {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: $darkLandline;
  }
  .date-time-label {
    font-size: 12px;
    color: $gray050;
    height: 12px;
    font-weight: 400;
  }
  .date-time-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .date-time-value {
    font-size: 12px;
    color: $darkLandline;
    padding-top: 4px;
    font-weight: 400;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
