@import "../../../colors.scss";

.change-password-container {
  padding: 40px 40px;
  background-color: $white;
  margin-top: 16px;

  .change-password-text {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 28px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .buttons-wrapper {
    margin-top: 114px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .pw-input-wrapper {
    width: 100%;
    position: relative;
    margin-left: 35px;

    &.no-margin {
      margin-left: 0;
    }
  }

  .pw-icon-wrapper {
    position: absolute;
    top: 33px;
    right: 15px;
    cursor: pointer;
  }
}
