@import "../../../colors.scss";

.videcom-reservation-container {
  display: flex;
  flex-direction: row;

  .videcom-input-container {
    margin: 0px 8px;
  }

  .loading {
    display: inline-block;
    visibility: hidden;
  }
}
