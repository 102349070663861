@import "../../../colors.scss";

.notificationsContainer {
  padding-top: 12px;
  padding-left: 22px;
  border-right: solid 1px $lightGray;
  padding-right: 22px;
  height: 40px;
  margin-top: 3px;
  cursor: pointer;
}
.notificationsCount {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $blue;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  color: $white;
  align-items: center;
  text-align: center;
  padding-top: 3px;
  width: 18px;
  height: 18px;
  margin-left: -5px;
  margin-top: -5px;
}
