@import "../../../colors.scss";

.transportation-type-checkbox-container {
  display: flex;
  flex-direction: row;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid $lightGray;
  margin-left: 14px;
  align-items: center;
  cursor: pointer;

  .transportation-type-name {
    margin-left: 12px;
    font-size: 14px;
    color: $darkLandline;
  }
}
