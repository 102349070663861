@import "../../../colors.scss";

.planning-table-container {
  margin-top: 30px;
  padding: 0px 20px 32px 32px;

  // TABLE HEAD
  .TableHead-container {
    .tableHeadRow {
      display: grid;
      grid-template-columns:
        minmax(128px, 1fr)
        minmax(106px, 1fr)
        minmax(118px, 1fr)
        minmax(101px, 1fr)
        minmax(74px, 1fr)
        minmax(73px, 1fr)
        minmax(173px, 1fr)
        minmax(204px, 1fr)
        minmax(109px, 1fr)
        26px;
    }
  }
  .THeadItem-container {
    text-transform: uppercase;
  }
  .THeadItem-container:nth-child(1) {
    margin-left: 16px;
  }
  // TABLE
  .table-container {
  }

  //TABLE ROW

  .table-row-container {
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 8px;
    margin-top: 8px;
    cursor: pointer;
    display: grid;
    grid-template-columns:
      minmax(128px, 1fr)
      minmax(106px, 1fr)
      minmax(118px, 1fr)
      minmax(101px, 1fr)
      minmax(74px, 1fr)
      minmax(73px, 1fr)
      minmax(173px, 1fr)
      minmax(204px, 1fr)
      minmax(109px, 1fr)
      26px;

    .statusTableCell-container {
      padding-left: 16px;
    }
    &.Unassigned {
    }
    &.Suggested {
    }
    &.Pending {
    }
    &.Approved {
      background-color: $alertInfoLight;
    }

    // COMPANY COLORS
    &.VIDECOM {
      background: $videcomBg;
      .row-item-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $mediumGray;
          }
        }
      }
    }
    &.SUNCOUNTRY {
      background: $white;
      .row-item-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $sunCountryInnerBtnColor;
          }
        }
      }
    }
    &.isExpress {
      background-color: $gray005;
      .td-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $mediumGray;
          }
        }
      }
    }
    &.isDoor2Door {
      .td-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $mediumGray;
          }
        }
      }
    }
    // New Booking
    &.booking {
      .row-item-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $mediumGray;
          }
        }
      }
    }
    &.Cancelled {
      background-color: $canceledRed;
      .row-item-container {
        .drop-down-table-container {
          .table-drop-down-inner-div {
            background-color: $cancelledInnerBtnColor;
          }
        }
      }
    }

    // TABLE TD
    .td-container {
      display: grid;
      place-items: center left;
      height: 64px;
    }
    // END OF TABLE TD

    // END OF TABLE ROW

    .formatedTableCell-inner-container {
      color: $darkLandline;
    }
    svg {
      fill: $darkLandline !important;
    }
  }
  .whiteColor {
    .formatedTableCell-inner-container {
      color: $white;
    }
    svg {
      fill: $white !important;
    }
    .drop-down-table-container {
      svg {
        fill: $darkLandline !important;
      }
    }
  }
  .planning-page-empty-state {
    position: absolute;
    transform: translateX(50%);
    padding-top: 100px;
    // TABLE TD
  }
  // TABLE TD
  .row-item-container {
    display: grid;
    place-items: center left;
    height: 52px; // if you changed this, make sure you change ROW_HEIGHT constant as well
  }
}
