@import "../../../colors.scss";

.side-bar-container {
  transition: width 0.3s linear;
  width: 260px;
  border-radius: 0 16px 0 0;
  position: fixed;
  z-index: 1;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #3c3835;

  &.isCollapsed {
    width: 104px;
    .collapse-sidebar-container {
      .collapse-sidebar-content {
        padding-left: 64px;
      }
      .collapse-sidebar-content-representative {
        padding-left: 64px;
      }
      .sidebar-label {
        display: none;
      }
    }
  }

  .collapse-sidebar-container {
    cursor: pointer;
    color: $white;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $gray080;

    svg {
      min-width: 16px;
      min-height: 16px;
    }

    .collapse-sidebar-content {
      transition: padding-left 0.3s linear;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-left: 119px;
    }
    .collapse-sidebar-content-representative {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 28px;
      padding-right: 24px;
      width: 100%;
    }

    .sidebar-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .logout {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 40px;
    display: flex;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, 0%);

    .logout-title {
      margin-left: 8px;
      color: $gray040;
    }
  }
}
