@import "../../../colors.scss";

.vehicle-card-field-list-container {
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 92px;
  padding-right: 120px;

  &.without-checkbox {
    padding-left: 52px;
  }

  .field {
    font-size: 12px;
    font-style: normal;
    color: $gray050;
    display: flex;
    width: 100px;
    min-width: 100px;
    align-items: center;
    text-transform: uppercase;
  }

  .avatar {
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .model {
    width: 180px;
    min-width: 180px;
  }
}
