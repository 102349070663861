@import "../../../colors.scss";

.date-filter-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;

  .date-filter-container {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    height: 32px;
    padding: 0 12px;
    background-color: $white;
    border: 1px solid $lightGray;
    border-radius: 8px;
    align-items: center;
    margin-left: 8px;
    color: $gray040;
    cursor: pointer;

    .date-filter-text {
      font-size: 14px;
    }

    .close-icon-wrapper {
      width: 14px;
      height: 14px;
      margin-left: 10px;
      cursor: pointer;
    }

    &.active {
      color: $darkLandline;
    }
  }
}
