@import "../../../colors.scss";

.bar-chart-container {
  display: flex;
  flex-direction: column;
  padding-top: 29px;

  .bar-chart-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    flex: 1;
  }

  .bar-chart-item-label {
    flex-shrink: 0;
    font-size: 14px;
    color: $gray050;
    margin-right: 30px;
  }

  .bar-chart-item-value {
    flex-shrink: 0;
    margin-left: 32px;
    font-size: 14px;
    width: 70px;
    white-space: nowrap;
  }
}
