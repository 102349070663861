@import "../../../colors.scss";

.React-modal {
  font-family: "Source Sans Pro", sans-serif;
  .React-modal-header-border {
    border-bottom: 1px solid $darkLandline;
    padding-bottom: 10px;
    .React-modal--dialog-content {
      padding-top: 20px;
    }
  }
  .React-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    height: 50px;
  }
  .modal-absolute-icon {
    svg {
      right: 16px;
      top: 16px;
      transform: none;
      fill: $gray050;
    }
  }

  .React-modal--dialog-content {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    min-height: 100px;
    &.centered {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .dialog-buttons {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .modal-title-close-btn {
    position: absolute;
    right: 0px;
    top: 6px;
    cursor: pointer;
  }
  .request-booking-title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    svg {
      fill: $darkLandline !important;
      width: 24px;
      height: 24px;
      margin-right: 19px !important;
    }
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
