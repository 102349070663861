@import "../../../colors.scss";
.driverShiftTable {
  // width from design 702px;
  margin: 0px auto;
  min-width: 600px;
  width: 100%;
  height: 387px;
  border-radius: 16px;
  background-color: $white;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  &__content {
    padding: 36px 40px 24px 40px;
    display: flex;
    .daysColumn {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      .day {
        padding-top: 28px;
        font-weight: bold;
        color: $gray050;
        font-size: 14px;
      }
    }

    .driverTable {
      // width from design 524px;
      width: 100%;
      margin-left: 27px;
      &__content {
        .tableHead {
          display: flex;
          justify-content: space-evenly;
          .tableData {
            color: $gray050;
            font-weight: bold;
            font-size: 14px;
          }
        }
        .tableData {
          width: 110px;
        }
        .tableRow__wrapper {
          padding-top: 15px;
          .tableRow {
            display: flex;
            justify-content: space-evenly;
            height: 42px;
            align-items: center;
            border-radius: 6px;
            position: relative;
            &:nth-child(-2n + 9) {
              background-color: $mediumGray;
            }
            .tableData {
              font-size: 14px;
              font-weight: 400;
              color: $darkLandline;
            }
            .editModal {
              position: absolute;
              right: 15px;
            }
            .offDutyIcon {
              position: absolute;
              right: 45px;
            }
          }
        }
      }
    }
  }
}
