@import "../../../colors.scss";
.drop-down-only-display-container {
  display: flex;
  padding: 4px 8px;
  position: relative;
  background-color: $mediumGray;
  border-radius: 8px;
}
.drop-down-table-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .email-notified-container {
    position: absolute;
    top: -2px;
    left: -13px;
    z-index: 1;
    width: 26px;
    height: 26px;
    .mozilla-bg {
      background-color: $white;
      display: grid;
      place-items: center;
      border-radius: 50%;
      box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.24);
      width: 24px;
      height: 24px;
    }
    .email-icon-popup-wrapper {
      position: relative;
    }

    .email-notification-popup {
      background-color: $white;
      position: absolute;
      top: -38px;
      padding: 6px 14px;
      box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
      white-space: nowrap;
      left: 18px;
      transform: translateX(-50%);
    }

    .email-notification-popup:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      bottom: -4px;
      left: 45%;

      border-top: 4px solid $white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .status-text {
      &.Sent {
        color: $primaryLandline;
      }

      &.Failed {
        color: $failedRed;
      }

      &.Pending {
        color: $pendingGray;
      }
    }
  }

  .table-drop-down-inner-div {
    position: relative;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 8px;
    white-space: nowrap;
    cursor: pointer;
    span + svg {
      margin-left: 4px !important;
    }
    &.Unassigned {
      background-color: $mediumGray;
    }
    &.Suggested {
      background-color: $mediumGray;
    }
    &.Pending {
      background-color: $mediumGray;
    }

    &.Approved {
      background-color: $mediumGray;
    }
    &.Cancelled {
      background-color: $alertErrorBackground;
    }
    &.disabled {
      span {
        opacity: 0.4;
      }
      svg {
        opacity: 0.4 !important;
      }
      cursor: not-allowed;
    }
    .unassigned-span {
      color: $gray050;
    }
    .dropdown-icon {
      position: absolute;
      right: 8px;
    }
  }
  .dropdown-content {
    box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    padding: 8px 0px;
    z-index: 2;
    position: absolute;
    top: 40px;
    min-width: 280px;
    background-color: $white;
    color: $darkLandline;

    &.isVehicle {
      left: -180px;
    }

    &.isVehicle:before {
      left: 260px;
    }

    &.show-on-top {
      transform: translate(0, -100%);
      top: -8px;
    }

    &.show-on-top:before {
      top: unset;
      border-bottom: unset;

      bottom: -6px;
      border-top: 6px solid $white;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }

    .dropdown-row {
      padding: 8px 26px;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      &.dead-leg {
        background-color: #f1f9f2;
      }
    }
    .dropdown-row:hover {
      background-color: $mediumGray;
    }
  }
  .dropdown-content:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    left: 164px;
    top: -6px;
  }
  .loading {
    display: flex;
    justify-content: center;
    .loading-label {
      color: $black;
    }
  }
  .empty-state-text {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .infinite-scroll-table-dropdown {
    display: block;
    height: 256px;
    overflow-y: auto;
  }
}
.planing-popup-element {
  background-color: $white;
  padding: 8px;
  position: absolute;
  top: -50px;
  left: 10px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
}
.search-driver-and-vehicle-container {
  padding: 16px 24px;
}
.driver-error-popup {
  display: flex;
  flex-direction: column;
  width: 220px;
  z-index: 10;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
  padding: 24px 22px 32px 22px;
  justify-content: center;
  align-items: center;

  .driver-error-title {
    font-size: 14px;
    margin-bottom: 14px;
    text-align: center;
  }

  .driver-error-text {
    font-size: 12px;
  }
}

.driver-error-popup:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid $white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  right: -8px;
  top: 54px;
}
