@import "../../../colors.scss";
.popup-container {
  position: absolute;
  top: 40px;
  z-index: 2;
  background-color: $white;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .buttons-container {
    display: flex;
    padding: 16px;
  }
}
