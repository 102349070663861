.radio-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  pointer-events: none;
  .input-radio {
    display: none;
  }
  input + label {
    //empty box svg

    background: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMDAwMTYgMS4zMzMzMUM0LjMyMDE2IDEuMzMzMzEgMS4zMzM1IDQuMzE5OTggMS4zMzM1IDcuOTk5OThDMS4zMzM1IDExLjY4IDQuMzIwMTYgMTQuNjY2NiA4LjAwMDE2IDE0LjY2NjZDMTEuNjgwMiAxNC42NjY2IDE0LjY2NjggMTEuNjggMTQuNjY2OCA3Ljk5OTk4QzE0LjY2NjggNC4zMTk5OCAxMS42ODAyIDEuMzMzMzEgOC4wMDAxNiAxLjMzMzMxWk04LjAwMDE2IDEzLjMzMzNDNS4wNTM1IDEzLjMzMzMgMi42NjY4MyAxMC45NDY2IDIuNjY2ODMgNy45OTk5OEMyLjY2NjgzIDUuMDUzMzEgNS4wNTM1IDIuNjY2NjUgOC4wMDAxNiAyLjY2NjY1QzEwLjk0NjggMi42NjY2NSAxMy4zMzM1IDUuMDUzMzEgMTMuMzMzNSA3Ljk5OTk4QzEzLjMzMzUgMTAuOTQ2NiAxMC45NDY4IDEzLjMzMzMgOC4wMDAxNiAxMy4zMzMzWiIgZmlsbD0iIzVDQkI2OSIgLz4KPC9zdmc+");
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0;
    cursor: pointer;
  }
  input:checked + label {
    //with checkbox svg
    background: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMDAwMDQgMS4zMzMzN0M0LjMyMDA0IDEuMzMzMzcgMS4zMzMzNyA0LjMyMDA0IDEuMzMzMzcgOC4wMDAwNEMxLjMzMzM3IDExLjY4IDQuMzIwMDQgMTQuNjY2NyA4LjAwMDA0IDE0LjY2NjdDMTEuNjggMTQuNjY2NyAxNC42NjY3IDExLjY4IDE0LjY2NjcgOC4wMDAwNEMxNC42NjY3IDQuMzIwMDQgMTEuNjggMS4zMzMzNyA4LjAwMDA0IDEuMzMzMzdaTTguMDAwMDQgMTMuMzMzNEM1LjA1MzM3IDEzLjMzMzQgMi42NjY3MSAxMC45NDY3IDIuNjY2NzEgOC4wMDAwNEMyLjY2NjcxIDUuMDUzMzcgNS4wNTMzNyAyLjY2NjcxIDguMDAwMDQgMi42NjY3MUMxMC45NDY3IDIuNjY2NzEgMTMuMzMzNCA1LjA1MzM3IDEzLjMzMzQgOC4wMDAwNEMxMy4zMzM0IDEwLjk0NjcgMTAuOTQ2NyAxMy4zMzM0IDguMDAwMDQgMTMuMzMzNFoiIGZpbGw9IiM1Q0JCNjkiLz4KPHBhdGggZD0iTTcuOTk5OTYgMTEuMzMzM0M5Ljg0MDkxIDExLjMzMzMgMTEuMzMzMyA5Ljg0MDkxIDExLjMzMzMgNy45OTk5NkMxMS4zMzMzIDYuMTU5MDEgOS44NDA5MSA0LjY2NjYzIDcuOTk5OTYgNC42NjY2M0M2LjE1OTAxIDQuNjY2NjMgNC42NjY2MyA2LjE1OTAxIDQuNjY2NjMgNy45OTk5NkM0LjY2NjYzIDkuODQwOTEgNi4xNTkwMSAxMS4zMzMzIDcuOTk5OTYgMTEuMzMzM1oiIGZpbGw9IiM1Q0JCNjkiLz4KPC9zdmc+Cg==");
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0;
  }
  .radio-button-text {
    margin-left: 8px;
  }
}
