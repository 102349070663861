@import "../../../colors.scss";

.formatedTableCell-container {
  .formatedTableCell-inner-container {
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    position: relative;
    min-width: 76px;
    span {
      white-space: nowrap;
    }
    svg,
    img {
      margin-right: 4px !important;
    }
  }
  &.Unassigned {
    svg {
      fill: $darkLandline !important;
    }
  }
  &.Suggested {
    svg {
      fill: $darkLandline !important;
    }
  }
  &.Pending {
    svg {
      fill: $darkLandline !important;
    }
  }
  &.Approved {
    svg {
      fill: $black !important;
    }
  }
  &.Cancelled {
    svg {
      fill: $black !important;
    }
  }
  &.New {
    svg {
      fill: $darkLandline !important;
    }
  }
  &.bordered {
    div {
      padding: 4px 10px;
      justify-content: flex-start;
      background-color: $mediumGray;
    }
  }

  .email-notified-container {
    position: absolute;
    top: 0px;
    right: -30px;
    width: 26px;
    height: 26px;

    .mozilla-bg {
      background-color: $white;
      display: grid;
      place-items: center;
      border-radius: 50%;
      box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.24);
      width: 24px;
      height: 24px;
    }
    .email-notification-popup {
      background-color: $white;
      color: $darkLandline;
      position: absolute;
      top: -40px;
      padding: 6px 14px;
      box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
      white-space: nowrap;
      left: 18px;
      transform: translateX(-50%);
    }

    .email-notification-popup:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      bottom: -4px;
      left: 45%;

      border-top: 4px solid $white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    .status-text {
      &.Sent {
        color: $primaryLandline;
      }

      &.Failed {
        color: $failedRed;
      }

      &.Pending {
        color: $pendingGray;
      }
    }
  }
}
