.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  pointer-events: none;

  &.disabled {
    opacity: 0.3;
  }
  .input-checkbox {
    display: none;
  }
  input + label {
    //empty box svg
    height: 14px;
    width: 14px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjY2NjcgMTIuNDQ0NEgyLjMzMzMzQzEuOTA1NTYgMTIuNDQ0NCAxLjU1NTU2IDEyLjA5NDQgMS41NTU1NiAxMS42NjY3VjIuMzMzMzNDMS41NTU1NiAxLjkwNTU2IDEuOTA1NTYgMS41NTU1NiAyLjMzMzMzIDEuNTU1NTZIMTEuNjY2N0MxMi4wOTQ0IDEuNTU1NTYgMTIuNDQ0NCAxLjkwNTU2IDEyLjQ0NDQgMi4zMzMzM1YxMS42NjY3QzEyLjQ0NDQgMTIuMDk0NCAxMi4wOTQ0IDEyLjQ0NDQgMTEuNjY2NyAxMi40NDQ0Wk0xMi40NDQ0IDBIMS41NTU1NkMwLjcgMCAwIDAuNyAwIDEuNTU1NTZWMTIuNDQ0NEMwIDEzLjMgMC43IDE0IDEuNTU1NTYgMTRIMTIuNDQ0NEMxMy4zIDE0IDE0IDEzLjMgMTQgMTIuNDQ0NFYxLjU1NTU2QzE0IDAuNyAxMy4zIDAgMTIuNDQ0NCAwWiIgZmlsbD0iI0U1RTVFNSIvPgo8L3N2Zz4K");
    display: inline-block;
    padding: 0;
    cursor: pointer;
  }
  input:checked + label {
    //with checkbox svg
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjQ0NDQgMEgxLjU1NTU2QzAuNyAwIDAgMC43IDAgMS41NTU1NlYxMi40NDQ0QzAgMTMuMyAwLjcgMTQgMS41NTU1NiAxNEgxMi40NDQ0QzEzLjMgMTQgMTQgMTMuMyAxNCAxMi40NDQ0VjEuNTU1NTZDMTQgMC43IDEzLjMgMCAxMi40NDQ0IDBaTTUuOTk2NjcgMTAuMzM2N0M1LjY5MzMzIDEwLjY0IDUuMjAzMzMgMTAuNjQgNC45IDEwLjMzNjdMMi4xMDc3OCA3LjU0NDQ0QzEuODA0NDQgNy4yNDExMSAxLjgwNDQ0IDYuNzUxMTEgMi4xMDc3OCA2LjQ0Nzc4QzIuNDExMTEgNi4xNDQ0NCAyLjkwMTExIDYuMTQ0NDQgMy4yMDQ0NCA2LjQ0Nzc4TDUuNDQ0NDQgOC42ODc3OEwxMC43OTU2IDMuMzM2NjdDMTEuMDk4OSAzLjAzMzMzIDExLjU4ODkgMy4wMzMzMyAxMS44OTIyIDMuMzM2NjdDMTIuMTk1NiAzLjY0IDEyLjE5NTYgNC4xMyAxMS44OTIyIDQuNDMzMzNMNS45OTY2NyAxMC4zMzY3VjEwLjMzNjdaIiBmaWxsPSIjNUNCQjY5Ii8+Cjwvc3ZnPgo=");
    height: 14px;
    width: 14px;
    display: inline-block;
    padding: 0;
  }
  .checkbox-text {
    margin-left: 8px;
  }
}
