@import "../../../colors.scss";

.dashboard-container {
  padding: 0px 20px 32px 20px;
  margin-right: 336px;
  margin-top: 40px;

  .TextInput {
    background-color: white;
    height: 120px;
    width: auto;
    padding: 15px 15px;
  }

  .unassigned-span {
    span {
      color: $gray050;
    }
  }

  .welcome-message-container {
    span {
      font-size: 20px;
      line-height: 24px;
      color: $gray050;
    }
    h2 {
      font-style: italic;
      font-weight: bold;
      margin-bottom: 48px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  .new-requests-avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    .avatar-text-container {
      span:nth-child(1) {
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  .new-requests-pnr-row {
    .first-row {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .new-requests-date-row {
    .first-row {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .new-requests-details-link {
    color: $alertInfoBlue;
    cursor: pointer;
  }
  .new-requests-company-representative-avatar {
    span:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    span:last-child {
      font-size: 12px;
      line-height: 16px;
      color: $gray050;
    }
  }
  .date-row {
    span:first-child {
      font-weight: 400;
      color: $darkLandline;
      font-size: 14px;
      line-height: 20px;
    }
    span:last-child {
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
    }
  }
  .customButton {
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 8px;
    width: 92px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .approveBtn {
    svg {
      fill: $successGreen !important;
    }
    transition: 0.3s;
    &:hover {
      background-color: $successGreen;
      svg {
        fill: $white !important;
      }
      color: $white;
    }
  }
  .denyBtn {
    svg {
      fill: $alertError !important;
    }
    transition: 0.3s;
    &:hover {
      background-color: $alertError;
      svg {
        fill: $white !important;
      }
      color: $white;
    }
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
}
.three-dots-button {
  padding-right: 4px;
  width: 16px;
  margin-top: 20px;
  margin-right: 5px;
.closeBtn{
  cursor: pointer;
  }
}

.vehicle-text {
  background-color: $mediumGray;
  width: 77px;
  height: 32px;
  border-radius: 8px;
  text-align: center;
  padding-top: 6px;
}
.no-vehicle-text {
  background-color: $mediumGray;
  width: 77px;
  height: 32px;
  border-radius: 8px;
  text-align: center;
  padding-top: 6px;
  color: $gray050;
}
.dashboard-popup-element {
  background-color: $white;
  padding: 8px;
  position: absolute;
  top: -30px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
}
@media only screen and (min-height: 1500px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
