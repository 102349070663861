@import "../../../colors.scss";

.booking-note {
  padding: 10px;

  .add-new-switch {
    cursor: pointer;
    display: flex;
    align-items: center;

    .label {
      color: $blue;
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      vertical-align: 4px;
    }
  }
}

.loading-booking-note {
  padding-left: 80px;
  .loading-label {
    color: $darkLandline;
  }
}

.saved-notes {
  margin-top: 20px;
}

.show-all-notes-button {
  text-align: center;
  font-size: 14px;
  color: $blue;
  cursor: pointer;
}

// modal overrides
.React-modal--dialog-content.internal,
.React-modal--dialog-content.public {
  .title-container {
    display: block;
    margin-bottom: 40px;

    .close-icon-wrapper {
      text-align: right;
      padding: 0px 20px;
      svg {
        cursor: pointer;
      }
    }

    .header-label {
      margin-bottom: 10px;
      padding: 0px 72px 0px 56px;
    }
  }

  .buttonsContainer {
    justify-content: flex-end;
    padding: 0px 72px;
  }

  .search-select-all-container {
    margin-left: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 32px;
    width: 264px;
    flex: 1;
    margin-bottom: 24px;
    cursor: pointer;

    .select-all-container {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 88px;
      height: 32px;
      font-size: 14px;
      color: $gray050;
      padding-top: 8px;
      margin-top: 4px;
    }
  }

  .inner-content {
    max-height: 380px;
    min-height: 380px;
    overflow: auto;
    padding: 0px 56px;
    width: 100%;

    .scrollable-container {
      padding: 0px;
    }

    .selection-item {
      height: 64px;
      border-radius: 8px;
      background: $white;
      margin-bottom: 12px;
      padding: 15px 15px 10px 16px;
      position: relative;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
      margin-top: 10px;
      cursor: pointer;

      .full-name {
        vertical-align: 9px;
        padding-left: 12px;
      }

      .checkbox-wrapper {
        position: absolute;
        right: 18px;
        top: 24px;
      }

      .company_color_box {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        vertical-align: 14px;
        padding-left: 10px;
        span {
          position: relative;
          top: 4px;
          left: 2px;
        }
      }

      img {
        border-radius: 50%;
        object-fit: cover;
      }

      .checkbox-container {
        display: inline;
      }
    }

    .selection-item:hover {
      background-color: $mediumGray;
    }
  }
}
