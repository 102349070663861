@import "../../../colors.scss";

.user-card-container {
  width: 100%;
  height: 80px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 8px;
  align-items: center;
  display: flex;
  margin-right: 16px;
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 8px;
  .user-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .user-avatar-image {
    width: 40px;
    height: 40px;
    border: 1px solid $placeholder;
    border-radius: 50%;
    display: flex;
  }
  .user-avatar-image {
    color: $white;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .user-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 24px;
    align-items: center;
    display: flex;
  }
  .user-info {
    flex-direction: row;
    display: flex;
  }
  .user-phone-text {
    color: $gray050;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
  }
  .user-phone-info {
    display: flex;
    flex: 1;
    font-size: 14px;
    line-height: 20px;
  }
  .user-email-text {
    color: $gray050;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
  }
  .user-email-info {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
  }
  .right-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .driver-info {
    font-size: 16px;
    color: $blue;
    cursor: pointer;
  }
}
