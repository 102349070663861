@import "../../../colors.scss";

.tab-layout-container {
  width: 100%;

  &.tab-layout-white-bg {
    background-color: $white;
  }

  .tab-content {
    padding-top: 24px;

    &.isPageLayout {
      padding: 32px 30px;
    }
  }
  .tabs-container {
    width: 100%;
    display: flex;
    background-color: $white;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid $lightGray;

    @media screen and (max-width: 500px) {
      .tab-container {
        flex: 1;
      }
    }

    &.isPageLayout {
      padding-left: 32px;
      justify-content: space-between;
    }
  }
  .tabs-navigation {
    display: flex;
    flex-direction: row;

    &.isPageLayout {
      padding-top: 34px;
    }
  }

  .right-side-layout-container {
    flex-direction: row;
    display: flex;
    padding-top: 12px;
    padding-right: 32px;
  }
}
