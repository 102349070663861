@import "../../../colors.scss";

.loadingAnimation {
  overflow: hidden;
  background-color: $darkOverlay;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  display: grid;
  place-items: center;

  .loadingAnimation--content {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      position: absolute;
      width: 100px;
      object-fit: cover;
    }
  }
}
