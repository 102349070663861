@import "../../../colors.scss";

.calendar-wrapper {
  display: flex;
  flex-direction: column;
}

.drop-down-calendar-container {
  position: relative;
  width: 136px;

  .calendar-container {
    position: absolute;
    width: 256px;
    z-index: 999;
    background-color: $white;
    &.alignRight {
      right: 0;
    }

    .clear-date-button {
      border-radius: 4px;
      left: 15px;
      width: 226px;
      margin: -40px auto;
      background-color: $red;
      text-align: center;
      color: $white;
      cursor: pointer;
    }

    .react-calendar {
      border-width: 0px;
      border-radius: 4px 4px 4px 4px;
      background: $white;
      box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);

      .react-calendar__viewContainer {
        padding: 0 16px 41px 14px;
      }
      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        border-radius: 50%;
        background-color: $mediumGray;
      }
      .react-calendar__month-view__weekdays__weekday {
        padding: 0;
        width: 32px;
        height: 32px;
        text-transform: none;
        color: $placeholder;
      }

      .react-calendar__tile.react-calendar__month-view__days__day {
        padding: 0;
        width: 32px;
        height: 32px;
        color: $black;
        margin-bottom: 8px;
      }

      .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        color: $placeholder;
      }
      .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
        background-color: $darkLandline;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: white;
      }

      .react-calendar__tile.react-calendar__tile--now {
        border-radius: 50%;
        background-color: $mediumGray;
      }
      .react-calendar__month-view__weekdays {
        font-weight: normal;
        color: $placeholder;
      }
      abbr {
        text-decoration: none;
      }
      .react-calendar__navigation {
        button {
          min-width: 30px;
          height: 40px;
        }

        button:enabled:hover {
          background-color: $mediumGray;
        }
      }

      .react-calendar__navigation__prev-button {
        padding: 0;
        justify-content: center;
        align-items: center;
        font-size: xx-large;
        height: 22px;
        font-size: 18px;
      }
      .react-calendar__navigation__prev2-button {
        padding: 0;
        justify-content: center;
        align-items: center;
        font-size: xx-large;
        height: 22px;
        font-size: 18px;
      }
      .react-calendar__navigation__next-button {
        padding: 0;
        justify-content: center;
        align-items: center;
        font-size: xx-large;
        height: 22px;
        font-size: 18px;
      }
      .react-calendar__navigation__next2-button {
        padding: 0;
        justify-content: center;
        align-items: center;
        font-size: xx-large;
        height: 22px;
        font-size: 18px;
      }
      .react-calendar__navigation__label {
        font-size: 16px;
        font-weight: 600;
      }
      .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
        max-width: 22px;
      }
    }
  }
}

.date-text-label {
  color: $gray070;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.calendar-date-text {
  position: relative;
  height: 40px;
  width: 200px;
  background-color: $mediumGray;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;

  .calendar-icon-wrapper {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &.disabled {
    cursor: unset;
  }

  &.warning {
    background-color: $alertLightRed;
    border: 1px solid $borderWarning;
  }

  &.filter-calendar-style {
    position: relative;
    background-color: $white;
    border: 1px solid $lightGray;
    color: $gray050 !important;
    height: 32px;
    border-radius: 8px;
    box-shadow: none;
    width: 136px;
    text-align: left;

    .calendar-icon-wrapper {
      top: 7px;
    }
  }

  &.driver-modal-style {
    position: relative;
    background-color: $mediumGray;
    color: $darkLandline !important;
    height: 40px;
    border-radius: 8px;
    box-shadow: none;
    width: 148px;
    text-align: left;

    .calendar-icon-wrapper {
      top: 10px;
    }
  }
}
