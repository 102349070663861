@import "../../../colors.scss";

.planning-page-container {
  .TextInput {
    background-color: $white;
    height: 120px;
    width: auto;
    padding: 15px 15px;
  }

  .unassigned-span {
    span {
      color: $gray050;
    }
  }
  .request-booking-text-row {
    padding: 16px 24px;
  }
  .upper-row-popup-text {
    padding: 36px 16px;
  }
  .request-booking-popup {
    min-height: 160px;
    min-width: 318px;

    &.show-on-top {
      transform: translate(0, -100%);
      top: -8px;
    }

    &.show-on-top:before {
      top: unset;
      border-bottom: unset;

      bottom: -6px;
      border-top: 6px solid $white;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }

  .request-booking-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    left: 30px;
    top: -6px;
  }

  .express-wrapper {
    position: relative;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    white-space: nowrap;
    cursor: pointer;
    background-color: $gray010;
    padding: 4px 8px;
    width: 153px;
  }
}

.pnr-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $darkLandline;
}

.planning-container {
  flex-direction: row;
  display: flex;
  flex: 1;
  padding: 18px 0px 16px 32px;
  border-bottom: 0.5px solid $placeholder;
  justify-content: space-between;
  align-items: center;
}
.planning-text {
  font-weight: 600;
  font-size: 24px;
}
.right-side-content {
  flex-direction: row;
  display: flex;
  padding-right: 20px;
  padding-left: 16px;
}
.notification-button {
  padding-right: 16px;
}

.current-date {
  padding-top: 20px;
}
.date-change-container {
  max-width: 320px;
  padding: 32px 0px 0px 64px;
}
.planning-page-calendar {
  top: 42px;
  left: 10px;
}
.upper-row-container {
  padding: 32px 20px 18px 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.upper-row-right-side {
  display: flex;
  flex-direction: row;
  margin-left: 120px;
}
.restriction-popup {
  background-color: $white;
  position: absolute;
  right: 32px;
  width: 330px;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  p {
    padding: 20px;
  }
}
.upper-row-button-popup {
  margin-top: 8px;
  left: -200px;
  min-height: 160px;
  min-width: 318px;
}
.upper-row-button-wrapper {
  position: relative;
  margin-left: 14px;
}
.separation-line {
  height: 40px;
  width: 1px;
  background-color: $placeholder;
  margin: 0px 6px 0px 20px;
  display: block;
}
.notify-buttons-container {
  position: absolute;
  top: 48px;
  z-index: 2;
  background-color: $white;
  box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
  border-radius: 4px;
  width: 150px;

  .notify-buttons-divider {
    height: 1px;
    display: flex;
    flex: 1;
    background-color: $mediumGray;
  }

  .notify-button {
    height: 48px;
    padding: 14px 24px;
    white-space: nowrap;
    cursor: pointer;

    &.disabled {
      cursor: default;
      color: $gray040;
      pointer-events: none;
    }
  }

  .notify-button:hover {
    background-color: $mediumGray;
  }
}
