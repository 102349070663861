@import "../../../colors.scss";

.add-new-body {
  background: $mediumGray;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px;

  &_content {
    width: 100%;
    border-radius: 4px;
    border: none;
    resize: none;
    padding: 10px;
    font-family: "Source Sans Pro";
    color: $lightBlack;
    line-height: 20px;
    font-size: 14px;
    height: 96px;
  }

  &_actions {
    color: $placeholder;
    font-size: 14px;
    text-align: right;
    padding-top: 8px;

    .saveEnabled {
      color: $lightBlack;
    }

    span {
      padding-left: 8px;
      cursor: pointer;
    }
  }
}
