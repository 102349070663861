@import "../../../colors.scss";

.timePicker-container {
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 8px;
    color: $gray070;
    font-size: 12px;
  }
}

.timePickerInput-container {
  border: none;
  position: relative;
  height: 40px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: $mediumGray;

  &.warning {
    background-color: $alertLightRed;
    border: 1px solid $borderWarning;
  }

  .timePickerInputs-wrapper {
    display: flex;
    font-size: 14px;
  }

  &.filterStyle {
    margin-top: 24px;
    background-color: $white;
    border: 1px solid $lightGray;
    width: 110px;
    height: 32px;

    .timePickerInputs-wrapper {
      display: flex;
      font-size: unset;
    }
  }

  &.driverStatusStyle {
    background-color: $white;
    border: 1px solid $lightGray;
    width: 132px;
    height: 32px;
  }

  .react-time-picker__inputGroup__input {
    outline: none;
  }

  .react-time-picker__wrapper {
    border: 0;
  }

  .timePickerInput-hours,
  .timePickerInput-minutes,
  .timePickerInput-abbreviation {
    display: flex;
    input,
    select {
      -moz-appearance: textfield;
      color: $darkLandline !important;
      font-size: 14px;
      width: 21px;
      border: 0;
      outline: none;
      text-align: center;
      background-color: $mediumGray;
      opacity: 1;
    }

    input::placeholder {
      color: $darkLandline !important;
    }

    input:disabled {
      color: unset !important;
    }

    select {
      width: 25px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
    }
  }

  &.filterStyle {
    .timePickerInputs-wrapper {
      color: $gray050 !important;
    }

    .timePickerInput-hours,
    .timePickerInput-minutes,
    .timePickerInput-abbreviation {
      display: flex;
      input,
      select {
        color: $gray050 !important;
        background-color: $white;
      }

      input::placeholder {
        color: $gray050 !important;
      }
    }
  }

  &.driverStatusStyle {
    .timePickerInputs-wrapper {
      color: $darkLandline !important;
    }

    .timePickerInput-hours,
    .timePickerInput-minutes,
    .timePickerInput-abbreviation {
      display: flex;
      input,
      select {
        color: $darkLandline !important;
        background-color: $white;
      }

      input::placeholder {
        color: $darkLandline !important;
      }
    }
  }
}
