@import "../../../colors.scss";

.vehicle-input-container {
  margin-top: 40px;
  margin-bottom: 56px;
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;

  .top-row-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .choose-vehicle-popup {
    background-color: $white;
    position: absolute;
    width: 117px;
    height: 75px;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .choose-status-available {
    text-align: center;
    padding: 0px 8px;
    font-size: 12px;
    height: 24px;
    border-radius: 16px;
    background-color: $successGreen;
    color: $white;
    font-style: normal;
    width: 90px;
    cursor: pointer;
  }

  .choose-status-out-of-service {
    background-color: $mediumGray;
    color: $darkLandline;
    text-align: center;
    padding: 0px 8px;
    font-size: 12px;
    height: 24px;
    border-radius: 16px;
    font-style: normal;
    width: 90px;
    cursor: pointer;
  }

  .status-button {
    width: auto;
    height: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .status-button-text {
      font-size: 14px;
      font-style: normal;
      color: $darkLandline;
    }

    &.status-button:hover {
      .status-button-text {
        color: $successGreen;
      }
    }
  }
}
