@import "../../../colors.scss";

.trip-request-table-container {
  // min-width: 804px;
  margin-top: 32px;
  // TABLE
  .table-container {
    width: 100%;
    margin-bottom: 8px;
    display: block;
    padding-bottom: 1px;
    border-radius: 8px;

    // tripRequests__wrapper splits request from suggestions
    .tripRequests__wrapper {
      .TableHead-container {
        position: relative;
        padding-left: 16px;
        padding-right: 8px;
        margin-top: 40px;

        .tableHeadRow {
          display: grid;
          grid-template-columns:
          minmax(99px, 1fr)
          minmax(131px, 1fr)
          minmax(122px, 1fr)
          minmax(119px, 1fr)
          minmax(114px, 1fr)
          minmax(116px, 1fr)
          minmax(48px, 0.5fr)
          16px;
        }
      }

      .table-body-container {
        position: relative;
        overflow-y: scroll;
        height: 360px;
        padding-top: 25px;
        margin: 0 0 0 -12px;
        padding-left: 12px;
        .table-row-container {
          padding-left: 16px;
          display: grid;

          grid-template-columns:
            minmax(99px, 1fr)
            minmax(131px, 1fr)
            minmax(122px, 1fr)
            minmax(119px, 1fr)
            minmax(114px, 1fr)
            minmax(116px, 1fr)
            minmax(48px, 0.5fr)
            16px;

          .statusTableCell-inner-container {
            width: 96px;
          }
        }
      }
      .table-row-container {
       
        position: relative;
        border-top: 0.5px solid $mediumGray;
        height: 56px;
        &:hover {
          background-color: $gray005;
        }

        .infoPopup{
        svg{
          cursor: pointer;
        }
        }
        .row-item-container {
          display: grid;
          place-items: center left;
          .drop-down-only-display-container {
            background-color: transparent ;
            padding: 0;
            .avatar-name-container{
              img,
              .companyColorWithLetters{
                border:1px solid $placeholder;
              
              }
            }
          }
        }
        .pnrBtn {
          cursor: pointer;
         
        }
      }
     
    }

    // TripSuggestion split
    .tripSuggestions__wrapper {
      .TableHead-container {
        position: relative;
        padding-left: 16px;
        //  compensate for scrollbar
        padding-right: 8px;
        margin-top: 40px;

        .tableHeadRow {
          display: grid;
          grid-template-columns:
            minmax(142px, 1fr)
            minmax(183px, 1fr)
            minmax(167px, 1fr)
            minmax(166px, 1fr)
            89px
            24px;
        }
      }

      .table-body-container {
        position: relative;
        overflow-y: scroll;
        height: 360px;
        padding-top: 25px;
        margin: 0 0 0 -12px;
        padding-left: 12px;
        .table-row-container {
          padding-left: 16px;
          display: grid;
          grid-template-columns:
            minmax(142px, 1fr)
            minmax(183px, 1fr)
            minmax(167px, 1fr)
            minmax(166px, 1fr)
            89px
            24px;

          .statusTableCell-inner-container {
            width: 96px;
          }
        }
      }
      .table-row-container {
        
        position: relative;
        border-top: 0.5px solid $mediumGray;
        height: 56px;
        &:hover {
          background-color: $gray005;
        }

        .row-item-container {
        
          display: grid;
          place-items: center left;
          .drop-down-only-display-container {
            background-color: transparent ;
            padding: 0;
            .avatar-name-container{
              img,
              .companyColorWithLetters{
                border:1px solid $placeholder;
              
              }
            }
          }
        }
        .row-item-container:nth-child(1) {
          .body2-semibold {
            cursor: pointer;
          }
        }
      }
    }
  }
  .row-item-container {
    display: grid;
    place-items: center left;
    height: 56px;
  }
}

// END OF TABLE TD

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $lightGray;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray040;
}
@media only screen and (min-height: 1100px) {
  .trip-request-table-container {
    .table-container {
      .table-body-container {
        min-height: 600px;
      }
    }
  }
}
