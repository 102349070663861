@import "../../../colors.scss";
.profile-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  color: $white;
  padding: 0 10px 0 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.openCollapse {
    padding: 0 10px 0 23px;
  }
  .capital-letters-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    color: $darkLandline;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    flex-shrink: 0;
  }
  .name-wrapper {
    margin-left: 16px;
    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;
      width: 150px;
    }
    .description {
      font-size: 14px;
      line-height: 20px;
      color: $gray050;
      width: 150px;
    }
  }
  .img {
    object-fit: cover;
    border-radius: 100%;
    flex-shrink: 0;
  }

  .description {
    line-height: 16px;
    letter-spacing: 2%;
    color: $gray050;
    size: 12px;
  }

  .username {
    line-height: 24px;
    size: 16px;
  }
}
