@import "../../../colors.scss";

.personal-information-container {
  padding: 40px 40px;
  background-color: $white;
  margin-top: 16px;
  border-radius: 8px;

  .image-upload-container {
    margin-bottom: 70px;
    display: flex;
    flex-direction: row;
  }

  .image-upload-text-container {
    margin-left: 52px;
    margin-top: 12px;

    .upload-photo-text {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 14px;
    }

    .upload-additional-info {
      font-size: 18px;
    }

    .image-buttons-container {
      margin-top: 26px;
      display: flex;
      flex-direction: row;
    }
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 14px;
  }

  .dropdown-wrapper {
    width: 100%;
    margin-left: 35px;
  }

  .buttons-wrapper {
    margin-top: 114px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.photo-modal {
  width: 392px;
  height: 412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  position: relative;

  .change-profile-photo-text {
    margin-top: 20px;
    font-size: 18px;
  }

  .upload-user-image {
    margin-top: 20px;
    padding: 6px 12px;
    color: $mediumBlue;
    border: 1px solid $mediumBlue;
    font-size: 14px;
    line-height: 14px;
    border-radius: 8px;
    cursor: pointer;
  }

  .modal-buttons-wrapper {
    margin-top: 56px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 24px;
  }

  .remove-photo-text {
    font-size: 18px;
    margin-top: 34px;
    width: 245px;
  }

  &.remove {
    height: 314px;
  }
  .close-button-container {
    position: absolute;
    right: 12px;
    top: 20px;
    cursor: pointer;
  }
}
