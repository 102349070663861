@import "../../../colors.scss";

.new-requests-rate-status {
  width: 10px;
  position: absolute;
  top: 0;
  left: 0px;
}
.new-requests-rate-status-content {
  display: grid;
  place-items: center;
  border-radius: 50px;
  box-shadow: 0px 2px 6px 1px $gray040;
}
.new-requests-rate-status-icon {
  position: absolute;
  top: 0px;
  left: -12px;
  width: 22px;
  height: 22px;
  background: $white;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  border-radius: 50%;
  padding: 4.33px;
}
.rate-status-popup-content {
  position: absolute;
  left: 24px;
  top: -7px;
  width: 87px;
  background-color: $white;
  box-shadow: 0px 2px 6px 1px $gray040;
  border-radius: 4px;
  display: grid;
  place-items: center;
  width: 100px;
  height: 30px;
  p {
    font-size: 12px;
  }
}
