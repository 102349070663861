@import "../../../colors.scss";

.mixed-pnr-popup-container {
  width: 220px;
  background-color: $white;
  position: absolute;
  left: -240px;
  top: -16px;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  padding: 24px 22px 20px 22px;
  cursor: default;

  .invisible-hover-box {
    width: 50px;
    height: 70px;
    position: absolute;
    right: -40px;
    top: 10px;
  }

  &.show-on-top {
    top: unset;
    bottom: -16px;

    .invisible-hover-box {
      top: unset;
      bottom: 10px;
    }
  }

  &.show-on-top:before {
    top: unset;
    bottom: 24px;
  }

  .mixed-pnr-dots-container {
    display: flex;
    width: 100%;
    margin-top: 14px;
    align-items: center;
    justify-content: center;
  }

  .mixed-pnr-popup-dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $lightGray;
    margin-right: 9px;
    cursor: pointer;

    &.isLast {
      margin-right: 0;
    }

    &.isActive {
      background-color: $primaryLandline;
    }
  }
}

.mixed-pnr-popup-container:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid $white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  right: -8px;
  top: 24px;
}
