@import "../../../colors.scss";

.availability-container {
  background-color: $white;

  .week-days {
    display: flex;
    flex-direction: row;
    flex: 1;

    .week-day-column {
      flex: 7;
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
    }

    .week-day-label {
      color: $gray050;
      margin-bottom: 20px;
    }

    .week-day-item-label {
      background-color: $mediumGreen;
      height: 24px;
      padding-top: 5px;

      &.isDayOff {
        background-color: $mediumGray;

        transition: background-color 0.7s ease;
      }
    }

    .week-day-item {
      height: 140px;
      border-top: 1px solid $lightGray;
      border-left: 1px solid $lightGray;
      min-width: 100px;

      &.lastInRow {
        border-right: 1px solid $lightGray;
      }

      &.lastInColumn {
        border-bottom: 1px solid $lightGray;
      }

      &.isToday {
        border: 1px solid $primaryLandline;
      }

      &.filterMark {
        background-color: #fff7cc;
      }
    }
  }

  .upper-row-right-side {
    display: flex;
    flex-direction: row;
  }

  .availability-upper-row-container {
    padding: 0 0 18px 0;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  .events-wrapper {
    padding: 10px 6px;
  }
}

.calendar-container {
  width: 256px;
  height: 313px;
  z-index: 999;
}
.upper-row-left-side {
  display: flex;
  flex-direction: row;
}
.driver-name-wrapper {
  margin-left: 120px;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}
