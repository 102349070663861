.avatar-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-text-container {
    display: flex;
    flex-direction: column;
  }
  img {
    border-radius: 100%;
    object-fit: cover;
  }
  span {
    margin-left: 8px;
  }
  .mark {
    background-color: brown;
  }
  .companyColorWithLetters {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    text-transform: uppercase;
  }
}
