.avatar-image-container {
  position: relative;
  top: -10px;
  margin-top: 15px;
  img {
    width: 64px;
    height: 64px;
    background-color: lightgray;
    border-radius: 50%;
    margin: 0px;
    position: absolute;
    top: 3px;
    left: 3px;
  }
  .imageWraper {
    width: 70px;
    height: 70px;
    border: 2px solid #10C492;
    border-radius: 50%;
    position: relative;
    background:white;
    margin: 0 auto;
  }
  .notActive{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    position: relative;
    background:white;
    margin: 0 auto;
  }
}
