@import "../../../colors.scss";

.search-dropdown-container {
  position: relative;
  margin-top: 24px;

  &.noTopMargin {
    margin-top: 0px;
  }
  .placeholder {
    color: $gray050;
  }
  .activeList {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    background-color: $white;
    z-index: 2;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    cursor: pointer;
    max-height: 150px;
    overflow-y: auto;
  }
  .item {
    padding: 7px 0px 7px 15px;
    align-items: center;
    display: flex;
  }
  .item:hover {
    background-color: $lightGray;
  }
  .arrow-wrapper {
    position: absolute;
    line-height: 0px;
    right: 8px;
    top: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    pointer-events: none;

    &.isFilter {
      top: 16px;
    }
  }
}

.label-text {
  color: $gray070;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
}
