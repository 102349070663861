@import "../../../colors.scss";

.quoting-page-container {
  .TextInput {
    background-color: $white;
    height: 120px;
    width: auto;
    padding: 15px 15px;
  }
}

.quoting-container {
  flex-direction: row;
  display: flex;
  flex: 1;
  padding: 18px 0px 16px 32px;
  border-bottom: 1px solid $placeholder;
  justify-content: space-between;
  align-items: center;
}

.quoting-tool-map-container {
  margin:10px;
  border: 3px solid $darkGray;
  border-radius: 8px;
}

.quoting-text {
  font-weight: 600;
  font-size: 24px;
}

.placeholder-point {
  display: flex;
  align-items: center;
  width: 355px;
  height: 40px;
  box-shadow: none;
  border: 1px solid $lightGray;
  background-color: $white;
  border-radius: 8px;
}

.quote-route-container {
  display: flex;

  div {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .quote-route-container {
    flex-wrap: wrap;
  }
}

.quote-input-container {
  padding: 10px;
  display: flex;

  svg {
    min-width: 20px;
    color: black;
  }

  .quote-input {

    .search-size {
      input {
        width: 100%;
      }
      margin-bottom: 10px;
    }

    .search-style {
      input {
        font-size: 24px;
        line-height: 48px;
        border-radius: 8px;
      }
    }

    .quoting-to-from {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 10px;
    }

    .placeholder-point {
      font-weight: bold;
      font-size: 24px;
      border-color: $lightBlack;
      padding: 10px 2px 10px 2px;
      height: auto;
      margin-bottom: 10px;
    }

    .checkbox-container {
      justify-content: left;
    }

    .get-quote-button-container {
      justify-content: center;
      text-align: right;
    }

    .get-quote-button {
      display: inline-block;
      font-size: 14px;
      background-color: $lightGray;
      text-align: center;
      padding: 5px;
      border-radius: 8px;
      border: 1px solid $darkGrayBorder;
    }

    .get-quote-button:hover {
      background-color: $darkGray;
      cursor: pointer;
    }

    .quote-input-footer {
      display: flex;
    }
    .autocomplete-item {
      font-size: 14px;
      line-height: 24px;
      padding: 2px;
      border-radius: 8px;
    }
    .autocomplete-item:hover {
      cursor: pointer;
      background-color: $primaryLandline;
      font-weight: bold;
    }
  }
}

.quote-output-container {

  padding: 10px;

  .quote-output {
    background-color: $primaryLandline;
    border-radius: 8px;
    color: $darkLandline;
    padding: 10px;
  }

  .quote-output-line-item {
    flex-direction: row;
    display: flex;
    font-size: 24px;
    border-bottom: 1px solid $darkLandline;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .quote-output-line-item-price {
    font-size: 64px;
    font-weight: bold;
  }
  .quote-output-line-item-value{
    text-align: right;
  }
}