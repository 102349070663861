@import "../../../colors.scss";

.date-container {
  margin-left: 10px;
}

.date-wrapper {
  display: flex;
  border: $gray070 solid 1px;
  border-radius: 8px;
  height: 42px;
  width: 232px;
  padding: 10px 12px;
  background-color: white;
  align-items: center;
  cursor: pointer;

  .date {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    flex: 1;
    text-align: center;
    color: $darkLandline;
  }
  .prev {
    border-radius: 10px;
    background-color: $mediumGray;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
  }

  .next {
    border-radius: 10px;
    background-color: $mediumGray;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
  }

  .next:hover {
    cursor: pointer;
    background-color: rgb(96, 126, 126);
  }

  .prev:hover {
    cursor: pointer;
    background-color: rgb(96, 126, 126);
  }
}
