@import "../../../colors.scss";

.additional-events {
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  position: relative;
}

.additional-events-popup {
  position: absolute;
  bottom: -10px;
  transform: translate(0, 100%);
  background-color: $white;
  padding: 24px 27px;
  box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
  border-radius: 4px;
  width: 215px;
  cursor: default;
  z-index: 9;

  &.shouldShowOnTop {
    bottom: unset;
    top: -10px;
    transform: translate(0, -100%);
  }

  &.shouldShowOnTop:before {
    border-bottom: unset;
    top: unset;

    bottom: -6px;

    border-top: 6px solid white;
  }
}

.additional-events-popup:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-bottom: 6px solid white;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  left: 20px;
  top: -6px;
}
