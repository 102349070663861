@import "../../../colors.scss";

.driver-status-container {
  position: relative;
  width: 32px;
  height: 32px;

  .driver-status-popup {
    position: absolute;
    right: -12px;
    transform: translate(100%, 0%);
    top: 6px;
    border-radius: 4px;
    box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
    padding: 4px 18px;
    white-space: nowrap;
    background-color: $white;
    font-size: 12px;
  }

  .driver-status-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-right: 6px solid $white;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    left: -6px;
    top: 6px;
  }
}
