@import "../../../colors.scss";
.avatar-name-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: space-between;

  &.withIcon {
    width: 100%;
  }

  .avatar-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.hideFullName {
    margin-right: 8px;
  }
  .avatar-text-container {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    margin-right: 10px;
  }
  img {
    border-radius: 50%;
    object-fit: cover;
  }
  span {
    margin-left: 8px;
  }
  .mark {
    background-color: brown;
  }
  .avatarWithLetters {
    background-color: $primaryLandline;
    color: $black;
    text-transform: uppercase;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }

  .icons-container {
    display: flex;
  }

  .icon-wrapper {
    display: flex;
    height: 40px;
    margin-left: 6px;
    align-items: center;
  }
}

.avatar-image-wrapper {
  cursor: url("/media/pointer-with-clock.png"), auto;
}

.driver-avatar-popup {
  position: fixed;
  padding: 6px 14px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(66, 64, 62, 0.1);
  white-space: nowrap;
  z-index: 10;
}

.driver-avatar-popup:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 4px solid transparent;
  border-top: 4px solid $white;
  border-right: 4px solid transparent;
  top: 32px;
  left: 58px;
}
