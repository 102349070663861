@import "../../../colors.scss";

.driver-info-container {
  height: 320px;
  box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
  border-radius: 8px;
  background-color: $white;
  display: flex;
  position: relative;
  justify-content: center;
  .driver-info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .driver-data-column {
      height: 104px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.relative {
        position: relative;
      }

      &.max-height {
        height: max-content;
        max-height: 104px;
      }
    }
    .driver-data-column-item {
      height: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      &.row {
        flex-direction: row;
      }
    }
    .driver-data-column-label {
      position: absolute;
      top: -27px;
      width: 100px;
      color: $gray050;
      font-size: 14px;
    }
    .driver-name {
      padding-top: 58px;
      font-size: 32px;
      font-weight: 600;
    }
    .driver-hours {
      padding-top: 8px;
      font-size: 14px;
      padding-bottom: 26px;
    }
  }
  .driver-status {
    color: $gray050;
    padding-right: 8px;
  }
  .note-box {
    display: block;
    display: -webkit-box;
    max-width: 100px;
    margin: 0 auto;
    line-height: 1.4;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    word-wrap: break-word;
  }
  .note-box-popup {
    position: absolute;
    bottom: -20px;
    right: 0px;
    transform: translate(0, 100%);
    width: 219px;
    padding: 20px 24px;
    background-color: $white;
    box-shadow: 0px 0px 16px 4px rgba(66, 64, 62, 0.12);
    border-radius: 4px;
    font-size: 14px;
    z-index: 999;
  }
  .note-box-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-bottom: 6px solid $white;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    right: 10px;
    top: -6px;
  }
  .driver-data-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 112px;
  }
  .edit-container {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }
  .edit-popup {
    position: absolute;
    right: 102px;
    transform: translate(100%, 0%);
    top: 6px;
    border-radius: 4px;
    box-shadow: 0px 4px 16px 2px rgba(40, 34, 30, 0.12);
    padding: 4px 18px;
    white-space: nowrap;
    font-size: 12px;
  }
  .edit-popup:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 6px solid $white;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -4px;
    top: 6px;
  }
  .driver-loading {
    display: flex;
    align-items: center;
  }
}

.charts-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;

  .bar-chart-wrapper {
    width: 100%;
    height: 260px;
    background-color: $white;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 16px;
    padding: 19px 40px;
    margin-right: 24px;
  }

  .pie-chart-wrapper {
    height: 260px;
    background-color: $white;
    box-shadow: 0px 2px 6px 1px rgba(66, 64, 62, 0.12);
    border-radius: 16px;
    padding: 19px 40px;
  }
}
.shiftSection__wrapper {
  margin: 24px auto;
  display: flex;
  justify-content: space-between;

  .limitationsBox {
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
