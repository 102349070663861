@import "../../../colors.scss";

.leg-connection-container {
  .leg-connection {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    height: 11px;
    position: relative;
  }
  .leg-connection-line {
    height: 1px;
    width: 150px;
    position: absolute;
    top: 5px;
    left: 11px;
    background-color: $darkLandline;
    z-index: 0;
  }
  .leg-type-icon-wrapper {
    height: 10px;
    width: 38px;
    background-color: $white;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    z-index: 5;
  }
  .leg-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  }
  .leg-info {
    font-size: 12px;
    color: $gray050;
    height: 12px;
    font-weight: 400;
  }
}
