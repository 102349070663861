@import "../../../colors.scss";

.list-view-container {
  max-width: 424px;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  padding-top: 8px;
  overflow-y: scroll;
  .row-column {
    display: flex;
    padding-right: 20px;
    &:hover {
      background-color: $mediumGray;
    }
    &.mark {
      background-color: $mediumGray;
    }
    &.disabled {
      opacity: 0.3;
    }
    .suggested-text {
      display: flex;
      width: 200px;
      align-items: center;
      color: $gray070;
      cursor: pointer;
    }
    .assigned-text {
      display: flex;
      width: 250px;
      align-items: center;
      color: $gray070;
      cursor: pointer;
    }
  }

  .loading-list-view {
    padding-left: 150px;
    margin-bottom: 20px;
    .loading-label {
      color: $darkLandline;
    }
  }
}

.search-company-container {
  padding: 15px 24px;
}
