@import "../../../colors.scss";

.sidebar-menu-container {
  margin-top: 80px;
  width: 100%;
  padding-left: 18px;
  a {
    color: $gray040;
  }
  svg {
    fill: $gray040 !important;
  }

  .sidebar-menu-item {
    &.is-settings {
      svg {
        stroke: $gray040 !important;
      }
    }
  }

  .sidebar-menu-item.sidebar-menu-item-active:hover {
    background-color: $primaryLandline;
  }

  .sidebar-menu-item:hover {
    background-color: $lightBlack;
  }

  .sidebar-menu-item-active {
    background-color: $primaryLandline;
    color: $darkLandline;
    svg {
      fill: $darkLandline !important;
    }

    &.is-settings {
      svg {
        stroke: $darkLandline !important;
      }
    }
  }
}
